/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useCallback } from 'react'
import {
  FormControl, InputLabel, Input, Button, Switch, Typography, Grid, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, Paper, Fab, TextField,
} from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import moment from 'moment'
import mimeTypes from 'mime'

import { makeStyles } from '@material-ui/styles'

// import { Main } from '@oclif/command'

import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'

import AuthContext from '../contexts/AuthContext'
import createEventQuery from '../graphql/mutation/createEvent'
import organizersQuery from '../graphql/query/organizers'
import Breadcrumb from '../components/BreadcrumbLayout'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))
const CreateEvent = () => {
  const classes = useStyles()
  const [eventNameEN, setEventNameEN] = useState('')
  const [organizer, setOrganizer] = useState('')
  const [shirts, setShirt] = useState([])
  const [slugShirt, setSlugShirt] = useState('')
  const [typeShirt, setTypeShirt] = useState('')
  const [imageShirt, setImageShirt] = useState('')
  const [tickets, setTickets] = useState([])
  const [ticketType, setTicketType] = useState('')
  const [dateTicket, setDateTicket] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [timeDate, setTimeDate] = useState(false)
  const [slugEvent, setSlugEvent] = useState('')
  const [codeEvent, setCodeEvent] = useState('')
  // const [logo, setLogo] = useState('')
  const [file, setFile] = useState({})
  const [QRSearch, setQRSearch] = useState(false)
  const [IDSearch, setIDSearch] = useState(false)
  const [uploadFace, setUploadFace] = useState(false)
  const [isFaceCheckin, setIsFaceCheckin] = useState(false)
  const [isShirtImage, setIsShirtImage] = useState(false)
  const [IDFace, setIDFace] = useState(false)
  const [print, setPrint] = useState(false)
  const [selfCheckin, setSelfCheckin] = useState(false)
  const [autoPickup, setAutoPickup] = useState(false)
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const { user } = useContext(AuthContext)
  const reader = new FileReader()
  const handleClose = () => {
    setOpen(false)
    window.location.reload()
  }
  const addShirt = useCallback(() => {
    const newShirt = {
      slug: slugShirt,
      type: typeShirt,
      image: imageShirt,
    }
    setShirt((oldShirts) => ([...oldShirts, newShirt]))
    setSlugShirt('')
    setImageShirt('')
    setTypeShirt('')
  }, [slugShirt, typeShirt, imageShirt, setShirt, setSlugShirt, setImageShirt, setTypeShirt])
  const addTicketType = useCallback(() => {
    const newTicket = {
      ticketType,
      dateTicket,
      startDate,
      endDate
    }
    setTickets((oldTickets) => ([...oldTickets, newTicket]))
    setTicketType('')
    setDateTicket('')
    setStartDate('')
    setEndDate('')
  }, [ticketType, dateTicket, startDate, endDate, setTickets, setTicketType, setDateTicket, setStartDate, setEndDate])
  const removeShirt = useCallback((index) => {
    setShirt((oldShirts) => ([...oldShirts.slice(0, index), ...oldShirts.slice(index + 1)]))
  }, [setShirt])
  const removeTicket = useCallback((index) => {
    setTickets((oldTickets) => ([...oldTickets.slice(0, index), ...oldTickets.slice(index + 1)]))
  }, [setTickets])
  const { data } = useQuery(organizersQuery)
  const [createEvent] = useMutation(createEventQuery)
  const handleSubmit = async (e) => {
    e.preventDefault()
    reader.readAsDataURL(file)
    reader.onload = async (event) => {
      const extType = mimeTypes.getExtension(file.type)
      const ext = mimeTypes.getType(extType)
      const response = await fetch(process.env.REACT_APP_UPLOAD_SERVER, {
        method: 'POST',
        body: JSON.stringify({
         ext: `${ext}`
        }),
        headers: {
          'Content-Type': 'application/json',
          
        }
      })
      const result = await response.json()
      await axios.put(result.uploadUri , file , {
        headers: {
          'Content-Type': `${ext}`,
          'x-goog-acl': 'public-read'
        }
      }).then(res =>{
          return createEvent(
            {
              variables: {
                record: {
                  name: eventNameEN,
                  organizerId: organizer,
                  slug: slugEvent,
                  code: codeEvent,
                  logo: result.downloadUri,
                  isFaceCheckin : isFaceCheckin,
                  isShirtImage: isShirtImage,
                  shirts,
                  tickets,
                  createdById: user._id,
                  options: {
                    search: {
                      qrcode: QRSearch,
                      idcard: IDSearch,
                    },
                    face: {
                      upload: uploadFace,
                      idcard: IDFace,
                    },
                    print,
                    selfCheckin,
                    autoPickup
                  },
  
                },
              },
            },
          ).then(() => {
            setOpen(true)
            history.push('/events')
          })
      })
    }
  }
  const selectFile = async (e) => {
    e.preventDefault()
    const tempFile = e.currentTarget.files[0]
    setFile(tempFile)
  }
  if (user.role !== 'Admin') {
    return ('Cannot Access')
  }
  // console.log(shirts)
  const handleStartDate = (e) => {
    const dateStart = e.target.value
    if(moment(dateStart).isAfter(endDate)){
      setTimeDate(true)
    } else {
      setTimeDate(false)
    }
    setStartDate(dateStart)
  }
  const handleEndDate = (e) => {
    const dateEnd = e.target.value
    if(moment(dateEnd).isBefore(startDate)){
      setTimeDate(true)
    } else {
      setTimeDate(false)
    }
    setEndDate(dateEnd)
  }
  return (
    <React.Fragment>
      <Breadcrumb path="/events" titleLink="Event" titleTypo="Create Event" />
      <br />
      <Grid
        className={classes.layout}
      // alignItems="center"
      >
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit}>

            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h4" gutterBottom>Create Event</Typography>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography style={{ marginTop: '20px' }} variant="h6" gutterBottom>Detail</Typography>
            </Grid>
            <Grid

              container
              spacing={3}
            >

              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName"> ชื่องาน [Event name]</InputLabel>
                  <Input
                    autoFocus
                    id="eventNameEN"
                    name="eventNameEN"
                    onChange={(e) => setEventNameEN(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel>ผู้จัดงาน [Organizer]</InputLabel>
                  <Select
                    id="organizer"
                    name="organizer"
                    onChange={(e) => setOrganizer(e.target.value)}
                    value={organizer}
                  // style={{ width: '200px' }}
                  >
                    {data ? data.organizerUsers.map((row) => (
                      <MenuItem key={row._id} value={row._id}>{row.name}</MenuItem>
                    ))
                      : (<MenuItem value="placeholder">Placeholder</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel>Slug Event</InputLabel>
                  <Input
                    id="slugEvent"
                    name="slugEvent"
                    onChange={(e) => setSlugEvent(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel>Code Event</InputLabel>
                  <Input
                    id="codeEvent"
                    name="codeEvent"
                    onChange={(e) => setCodeEvent(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel>โลโก้งาน [Logo Photo]</InputLabel>
                  <Button variant="contained" size="small" component="label" className={classes.button} style={{ marginTop: '20px', marginLeft: '400px', width: '120px' }} startIcon={<CloudUploadIcon />}>
                    {file.name ? file.name : 'เลือกรูปภาพ'}
                    <input
                      onChange={selectFile}
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      style={{ display: 'none' }}
                    />
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <FormControl margin="normal" fullWidth required>
                <InputLabel>[Cover Photo]</InputLabel>
                <Input
                  id="Logo"
                  name="Logo"
                  onChange={e => setLogo(e.target.value)}
                />
              </FormControl>
            </Grid> */}
            {shirts.map((k, index) => (
              <div key={`${k.slug}-shirts`}>

                <Typography style={{ marginTop: '20px', marginBottom: '10px' }} variant="h6" gutterBottom>Shirt{index + 1}</Typography>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="outlined-read-only-input"
                      label="Slug Shirt"
                      defaultValue={k.slug}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <TextField
                      id="outlined-read-only-input"
                      label="ชนิดเสื้อ [Type]: "
                      defaultValue={k.type}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <TextField
                      style={{ height: '32px' }}
                      label="รูปภาพเสื้อ [Image]:"
                      defaultValue={k.image}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />
                  </Grid>
                  <Grid item xs={12} sm={1} style={{ marginTop: '20px' }}>
                    <Fab

                      variant="extended"
                      style={{ height: '32px' }}
                      size="small"
                      color="primary"
                      aria-label="add"
                      onClick={() => removeShirt(index)}
                    >
                      <DeleteIcon />
                    </Fab>
                  </Grid>
                </Grid>

              </div>
            ))}

            <Typography style={{ marginTop: '20px', marginBottom: '10px' }} variant="h6" gutterBottom>Shirt Detail Entry</Typography>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>Slug Shirt</InputLabel>
                <Input
                  autoFocus
                  id="slugShirt"
                  name="slugShirt"
                  onChange={(e) => setSlugShirt(e.target.value)}
                  value={slugShirt}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>ชนิดเสื้อ [Type]</InputLabel>
                <Input

                  autoFocus
                  id="typeShirt"
                  name="typeShirt"
                  onChange={(e) => setTypeShirt(e.target.value)}
                  value={typeShirt}

                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>Image Shirt</InputLabel>
                <Input
                  autoFocus
                  id="imageShirt"
                  name="imageShirt"
                  onChange={(e) => setImageShirt(e.target.value)}
                  value={imageShirt}

                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="add"
                onClick={addShirt}
              >

                <AddIcon />
                Add Shirt

              </Fab>
            </Grid>

            {tickets.map((k, index) => (
              <div key={`${k.ticketType}`}>

                <Typography style={{ marginTop: '20px', marginBottom: '10px' }} variant="h6" gutterBottom>Ticket{index + 1}</Typography>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="outlined-read-only-input"
                      label="ticketType"
                      defaultValue={k.ticketType}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <TextField
                      id="outlined-read-only-input"
                      label="StartDate: "
                      defaultValue={k.startDate}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <TextField
                      id="outlined-read-only-input"
                      label="EndDate: "
                      defaultValue={k.endDate}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />
                  </Grid>

                  <Grid item xs={12} sm={1} style={{ marginTop: '20px' }}>
                    <Fab

                      variant="extended"
                      style={{ height: '32px' }}
                      size="small"
                      color="primary"
                      aria-label="add"
                      onClick={() => removeTicket(index)}
                    >
                      <DeleteIcon />
                    </Fab>
                  </Grid>
                </Grid>

              </div>
            ))}

            <Typography style={{ marginTop: '20px', marginBottom: '10px' }} variant="h6" gutterBottom>Ticket</Typography>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>ticket type</InputLabel>
                <Input
                  autoFocus
                  id="ticketType"
                  name="ticketType"
                  onChange={(e) => setTicketType(e.target.value)}
                  value={ticketType}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>StartDate</InputLabel>
                <Input

                  autoFocus
                  id="startDate"
                  name="startDate"
                  type="date"
                  onChange={e => handleStartDate(e)}

                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>EndDate</InputLabel>
                <Input

                  autoFocus
                  id="endDate"
                  name="endDate"
                  type="date"
                  onChange={e => handleEndDate(e)}

                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="add"
                disabled = {timeDate}
                onClick={addTicketType}
              >

                <AddIcon />
                Add Ticket Type

              </Fab>
            </Grid>

            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom>Print</Typography>
            <Grid

              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setPrint(e.target.checked)}
                    />
                  )}
                  label="Print"
                />
              </Grid>
            </Grid>
            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom> Search Modes</Typography>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setQRSearch(e.target.checked)}
                    />
                  )}
                  label="QR Code"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setIDSearch(e.target.checked)}
                    />
                  )}
                  label="ID Card"
                />
              </Grid>
            </Grid>
            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom>Face Detection</Typography>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setUploadFace(e.target.checked)}
                    />
                  )}
                  label="From Upload"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setIsFaceCheckin(e.target.checked)}
                    />
                  )}
                  label="FaceCheckin"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {IDSearch && (
                  <div>
                    <FormControlLabel
                      control={(
                        <Switch
                          onChange={(e) => setIDFace(e.target.checked)}
                        />
                      )}
                      label="ID Card"
                    />

                  </div>
                )}
              </Grid>
            </Grid>
            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom>Self Checkin</Typography>
            <Grid

              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setSelfCheckin(e.target.checked)}
                    />
                  )}
                  label="selfCheckin"
                />
              </Grid>
            </Grid>
            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom> Others</Typography>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setIsShirtImage(e.target.checked)}
                    />
            )}
                  label="Shirt-Image"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setAutoPickup(e.target.checked)}
                    />
                  )}
                  label="autoPickup"
                />
              </Grid>
            </Grid>
            <div className={classes.buttons}>
              <Button
                style={{ marginTop: '20px', marginBottom: '20px' }}
                type="submit"
                // fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Submit
              </Button>
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Event Created</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Event has been created.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </Paper>
      </Grid>
    </React.Fragment>
  )
}

export default CreateEvent
