/* eslint-disable no-underscore-dangle */
import React, { useState, useContext } from 'react'
import {
  FormControl, InputLabel, Input, Button, Typography, Grid, Select, MenuItem, makeStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper,
} from '@material-ui/core'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import createAdminMutation from '../graphql/mutation/createAdmin'
import createStaffMutation from '../graphql/mutation/createStaff'
import createOrganizerMutation from '../graphql/mutation/createOrganizer'
import setPasswordMutation from '../graphql/mutation/setPassword'

import AuthContext from '../contexts/AuthContext'
import Breadcrumb from '../components/BreadcrumbLayout'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))
const CreateEvent = () => {
  const classes = useStyles()
  const [userName, setUserName] = useState('')
  const [loginName, setLoginName] = useState('')
  const [pass1, setPass1] = useState('')
  const [pass2, setPass2] = useState('')

  const [role, setRole] = useState('Organizer')
  const [createAdmin] = useMutation(createAdminMutation)
  const [createStaff] = useMutation(createStaffMutation)
  const [createOrganizer] = useMutation(createOrganizerMutation)
  const [setPassword] = useMutation(setPasswordMutation)

  const [open, setOpen] = useState(false)
  const [passwordOpen, setPasswordOpen] = useState(false)
  const history = useHistory()
  const { user } = useContext(AuthContext)
  if (user.role === 'Staff') {
    return ('Cannot Access')
  }
  const handleClose = () => {
    setOpen(false)
    setPasswordOpen(false)
    window.location.reload()
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (pass1 === pass2) {
        if (role === 'Admin') {
          createAdmin(
            {
              variables: {
                record: {
                  createdById: user._id,
                  name: userName,
                  username: loginName,
                },
              },
            },
          ).then((data) => {
            setPassword(
              {
                variables: {
                  id: data.data.createAdminUser.recordId,
                  password: pass1,
                },
              },
            )
          },
          ).then(setOpen(true))
        } else if (role === 'Organizer') {
          console.log({
            variables: {
              record: {
                createdById: user._id,
                name: userName,
                username: loginName,
              },
            },
          })
          createOrganizer(
            {
              variables: {
                record: {
                  createdById: user._id,
                  name: userName,
                  username: loginName,
                },
              },
            },
          ).then((data) => {
            setPassword(
              {
                variables: {
                  id: data.data.createOrganizerUser.recordId,
                  password: pass1,
                },
              },
            )
          },
          ).then(() => {
            setOpen(true)
            history.push('/users')
          })
        } else {
          createStaff(
            {
              variables: {
                record: {
                  createdById: user._id,
                  organizerId: user._id,
                  name: userName,
                  username: loginName,
                },
              },
            },
          ).then((data) => {
            setPassword(
              {
                variables: {
                  id: data.data.createStaffUser.recordId,
                  password: pass1,
                },
              },
            )
          },
          ).then(() => {
            setOpen(true)
            history.push('/users')
          })
        }
      } else {
        setPasswordOpen(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const roles = ['Organizer', 'Staff']
  if (user.role === 'Admin') {
    roles.push('Admin')
  }
  return (
    <React.Fragment>
      <Breadcrumb path="/users" titleLink="User" titleTypo="Create User" />
      <Grid
        className={classes.layout}
      // alignItems="center"
      >
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit}>

            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h4" gutterBottom>Create User</Typography>
            <Grid
              item
              xs={12}
              sm={12}
            >
              <Typography style={{ marginTop: '20px' }} variant="h6" gutterBottom>Detail </Typography>
            </Grid>

            <Grid

              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">Name</InputLabel>
                  <Input
                    autoFocus
                    id="organizerName"
                    name="organizerName"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">Username</InputLabel>
                  <Input
                    autoFocus
                    id="email"
                    name="email"
                    onChange={(e) => setLoginName(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="age-helper">Password</InputLabel>
                  <Input
                    label="Password"
                    className={classes.textField}
                    type="password"
                    onChange={(e) => setPass1(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="age-helper">Confirm Password</InputLabel>
                  <Input
                    label="Confirmed Password"
                    className={classes.textField}
                    type="password"
                    onChange={(e) => setPass2(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={4}
              style={{ marginTop: '20px', marginBottom: '20px' }}
            >
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl} fullWidth required>
                  <InputLabel htmlFor="age-helper">Role</InputLabel>
                  <Select
                    select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    inputProps={{
                      name: 'role',
                      id: 'role-helper',
                    }}
                  >
                    {
                      roles.map((row) => (
                        <MenuItem key={row} value={row}>{row}</MenuItem>
                      ),
                      )
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.buttons}>
              <Button
                style={{ marginTop: '20px', marginBottom: '20px' }}
                type="submit"
                // fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Submit
              </Button>
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">User Created</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {role} {userName} has been created.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={passwordOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">User Created</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Passwords do not match
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </Paper>
      </Grid>
    </React.Fragment>
  )
}

export default CreateEvent
