import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { ListItem, ListItemText, Grid } from '@material-ui/core'

const ListMenuItem = (props) => {
  const { path, title } = props
  const { pathname } = useLocation()
  return (
    <Grid
      container
      justify="center"
    >
      <ListItem button component={Link} selected={pathname === path} to={path}>
        <ListItemText primary={title} />
      </ListItem>
    </Grid>
  )
}
ListMenuItem.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.shape().isRequired,
}

export default ListMenuItem
