import React, { useContext } from 'react'
import { Link as Linkreact } from 'react-router-dom'
import {
  Typography, Grid, Fab, Divider,
} from '@material-ui/core'
import EventNoteSharpIcon from '@material-ui/icons/EventNoteSharp'
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp'
// import { makeStyles } from '@material-ui/styles'

import Breadcrumb from '../components/BreadcrumbLayout'

import AuthContext from '../contexts/AuthContext'

// const useStyles = makeStyles(({ spacing }) => ({
//   root: {
//     width: '100%',
//     overflowX: 'auto',
//   },
//   card: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   nested: {
//     paddingLeft: spacing(4),
//   },
// }))
const Home = () => {
  // const classes = useStyles()
  const { user } = useContext(AuthContext)
  return (
    <div>
      <Breadcrumb />
      {/* <Paper className={classes.root} style={{ marginTop: '10px' }}> */}
      <Typography style={{ marginTop: '20px', marginBottom: '20px', marginLeft: '20px' }} variant="h5">Home</Typography>
      <Grid
        container
        // direction="columm"
        justify="flex-start"
        alignItems="flex-end"
        // className={classes.card}
        style={{ marginTop: '20px', marginBottom: '20px', marginLeft: '20px' }}
      >
        {user.role !== 'Staff'
          ? (
            <div>
              <Fab variant="extended" to="/users" color="primary" style={{ height: '32px' }} component={Linkreact}>
                <AccountCircleSharpIcon />
                Users
              </Fab>
              <Fab variant="extended" to="/events" color="primary" style={{ height: '32px' }} component={Linkreact}>
                <EventNoteSharpIcon />
                Events
              </Fab>
            </div>
          ) : null}
      </Grid>
      <Divider />
      {/* </Paper> */}
    </div>
  )
}

export default Home
