import gql from 'graphql-tag'

export default gql`query participant($filter:FilterFindOneParticipantInput){
    participant(filter:$filter){
      firstname
      lastname
      bib
      displayName
      ageCategory
      gender
      row
      status
      nationality
      nationalId
      options
    }
  }`
