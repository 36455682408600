import React, { useContext, Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import {
  List, Typography, IconButton,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'
import { People as PeopleIcon, Assignment as AssignmentIcon, ExitToApp as LogoutIcon } from '@material-ui/icons'

import AuthContext from './contexts/AuthContext'
import ThemeLayout from './theme/ThemeLayout'
import ListMenuItem from './components/ListMenuItem'
import Home from './pages/Home'
import LoginPage from './pages/LoginPage'
import IdcardReaderPage from './pages/IdcardReaderPage'
import CreateEventPage from './pages/CreateEventPage'
import CreateOrganizerPage from './pages/CreateUserPage'
import EventsPage from './pages/Events'
import UsersPage from './pages/Users'
import EventPage from './pages/Event'
import editParticipantPage from './pages/editParticipantPage'
import addParticipantPage from './pages/addParticipantPage'
import editEventPage from './pages/editEventPage'
import editUserPage from './pages/editUserPage'
import StatsPage from './pages/StatsPage'

const useStyles = makeStyles(({ spacing }) => ({
  grow: {
    flexGrow: 1,
  },
  nested: {
    paddingLeft: spacing(4),
  },
}))

const App = () => {
  const classes = useStyles()
  const { user, removeToken } = useContext(AuthContext)
  const logout = () => {
    removeToken()
  }
  if (!user) {
    return (
      <LoginPage />
    )
  }
  return (

    <ThemeLayout
      header={(
        <Fragment>
          <Typography variant="h6" color="inherit" noWrap className={classes.grow}>Race Pack</Typography>
          <Typography variant="h6" color="inherit" noWrap style={{ alignItems: 'center' }}>{user.name}</Typography>
          <IconButton color="inherit" onClick={logout}>
            <LogoutIcon />
          </IconButton>
        </Fragment>
      )}
      menus={(
        <List dense component="nav">
          {user.role !== 'Staff' ? (
            <Fragment>
              {/* <List
                dense
                component="nav"
                subheader={(
                  <ListSubheader component="div" id="nested-list-subheader">
                    Users
                  </ListSubheader>
                )}
              > */}
              {/* <ListMenuItem path="/user/create" title="Create User" /> */}
              <ListMenuItem path="/users" title={<Typography style={{ display: 'flex' }}> <PeopleIcon /> User</Typography>} />

              {/* </List> */}
              {/* <List
                dense
                component="nav"
                subheader={(
                  <ListSubheader component="div" id="nested-list-subheader">
                    Event
                  </ListSubheader>
                )}
              />
              <ListMenuItem path="/event/create" title="Create Event" /> */}
            </Fragment>
          ) : null}
          <ListMenuItem path="/events" title={<Typography style={{ display: 'flex' }}> <AssignmentIcon /> Event</Typography>} />
        </List>
      )}
    >
      <Switch>
        <Route path="/demo/idcard" component={IdcardReaderPage} />
        <Route path="/event/:eventId/stats" component={StatsPage} />
        <Route path="/event/create" component={CreateEventPage} />
        <Route path="/events" component={EventsPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/user/create" component={CreateOrganizerPage} />
        <Route path="/event/:eventId/participant/add" component={addParticipantPage} />
        <Route path="/event/:eventId/participant" component={EventPage} />
        <Route path="/event/:eventId/:participant/edit" component={editParticipantPage} />
        <Route path="/event/:eventId/edit" component={editEventPage} />
        <Route path="/user/:userId/edit" component={editUserPage} />
        <Route path="/home" component={Home} />
        <Route component={Home} />
      </Switch>
    </ThemeLayout>
  )
}

export default App
