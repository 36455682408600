/* eslint-disable no-underscore-dangle */
import React, { useState,useContext, useEffect,useCallback } from 'react'
import {
  FormControl, InputLabel, Input,TextField,Fab, Button, Switch, Typography, Grid, FormControlLabel, Dialog,Select,MenuItem, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper,
} from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import moment from 'moment'
import mimeTypes from 'mime'

import { makeStyles } from '@material-ui/styles'
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'
import axios from 'axios'

import { useMultiState } from '../hooks'
import AuthContext from '../contexts/AuthContext'

import eventByIdQuery from '../graphql/query/eventById'
import organizersQuery from '../graphql/query/organizers'
import updateEventMutation from '../graphql/mutation/editEvent'
import Breadcrumb from '../components/BreadcrumbLayout'

const initialState = {
  eventNameEN: '',
  slugEvent:'',
  organizer: '',
  codeEvent:'',
  logo:'',
  startDate:'',
  endDate: '',
  QRSearch: false,
  IDSearch: false,
  isFaceCheckin: false,
  isShirtImage : false,
  uploadFace: false,
  IDFace: false,
  print: false,
  dialogue: false,
}
const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))
const EditEvent = () => {
  const [file, setFile] = useState({})
  const [shirts, setShirt] = useState([])
  const [slugShirt, setSlugShirt] = useState('')
  const [typeShirt, setTypeShirt] = useState('')
  const [imageShirt, setImageShirt] = useState('')
  const [tickets, setTickets] = useState([])
  const [ticketType, setTicketType] = useState('')
  const [dateTicket, setDateTicket] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [timeDate, setTimeDate] = useState(false)
  const classes = useStyles()
  const { eventId } = useParams()
  const [state, setState] = useMultiState(initialState)
  const { user } = useContext(AuthContext)
  const { data: data2 } = useQuery(organizersQuery)
  const { data } = useQuery(eventByIdQuery, { variables: { _id: eventId } })
  const addShirt = useCallback(() => {
    const newShirt = {
      slug: slugShirt,
      type: typeShirt,
      image: imageShirt,
    }
    console.log(newShirt, 'newShirt');
    setShirt((oldShirts) => ([...oldShirts, newShirt]))
    setSlugShirt('')
    setImageShirt('')
    setTypeShirt('')
  }, [slugShirt, typeShirt, imageShirt, setShirt, setSlugShirt, setImageShirt, setTypeShirt])
  const addTicketType = useCallback(() => {
    const newTicket = {
      ticketType,
      dateTicket,
      startDate,
      endDate
    }
    setTickets((oldTickets) => ([...oldTickets, newTicket]))
    setTicketType('')
    setDateTicket('')
    setStartDate('')
    setEndDate('')
  }, [ticketType, dateTicket, startDate, endDate, setTickets, setTicketType, setDateTicket, setStartDate, setEndDate])
  const removeShirt = useCallback((index) => {
    setShirt((oldShirts) => ([...oldShirts.slice(0, index), ...oldShirts.slice(index + 1)]))
  }, [setShirt])
  const removeTicket = useCallback((index) => {
    setTickets((oldTickets) => ([...oldTickets.slice(0, index), ...oldTickets.slice(index + 1)]))
  }, [setTickets])
  const [updateEvent] = useMutation(updateEventMutation)
  const history = useHistory()
  const reader = new FileReader()
  useEffect(() => {
    if (data) {
      // console.log(data)
      setState(
        {
          eventNameEN: data.eventId.name,
          slugEvent: data.eventId.slug,
          organizer: data.eventId.organizerId,
          logo : data.eventId.logo,
          codeEvent : data.eventId.code,
          QRSearch: data.eventId.options.search.qrcode,
          IDSearch: data.eventId.options.search.idcard,
          isFaceCheckin : data.eventId.isFaceCheckin,
          isShirtImage : data.eventId.isShirtImage,
          uploadFace: data.eventId.options.face.upload,
          IDFace: data.eventId.options.face.idcard,
          print: data.eventId.options.print,
          selfCheckin: data.eventId.options.selfCheckin,
          autoPickup: data.eventId.options.autoPickup,
          slugShirt: data.eventId.shirts.slug,
          imageShirt: data.eventId.shirts.image,
          typeShirt: data.eventId.shirts.type,
          ticketType: data.eventId.tickets.ticketType,
          dateTicket: data.eventId.tickets.dateTicket,
          startDate: data.eventId.tickets.startDate,
          endDate: data.eventId.tickets.endDate
        }
      )
      let st = data.eventId.shirts.map((item) => {
        return {
          slug: item.slug, image: item.image, type: item.type
        }
      })
      setShirt(st)
      let tc = data.eventId.tickets.map((item) => {
        return {
          ticketType: item.ticketType, startDate: item.startDate, endDate: item.endDate
        }
      })
      setTickets(tc)

    }
  }, [data, setState])
  const handleClose = () => {
    setState({ dialogue: false })
    history.push(`/event/${eventId}/participant`)
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
        updateEvent(
          {
            variables: {
              record: {
                _id: eventId,
                name: state.eventNameEN,
                organizerId: state.organizer,
                slug: state.slugEvent,
                code: state.codeEvent,
                logo: state.logo,
                isFaceCheckin: state.isFaceCheckin,
                isShirtImage : state.isShirtImage,
                updatedById: user._id,
                options: {
                  search: {
                    qrcode: state.QRSearch,
                    idcard: state.IDSearch,
                  },
                  face: {
                    upload: state.uploadFace,
                    idcard: state.IDFace,
                  },
                  print: state.print,
                  selfCheckin: state.selfCheckin,
                  autoPickup: state.autoPickup,
                },
                shirts,
                tickets
              },
            },
          }
        )
      setState({ dialogue: true })
  }
  const selectFile = async (e) => {
    e.preventDefault()
    const tempFile = e.currentTarget.files[0]
    setFile(tempFile)
    console.log(tempFile,'sesese');
    reader.readAsDataURL(tempFile)
    reader.onload = async (event) => {
      const extType = mimeTypes.getExtension(tempFile.type)
      const ext = mimeTypes.getType(extType)
      const response = await fetch(process.env.REACT_APP_UPLOAD_SERVER, {
        method: 'POST',
        body: JSON.stringify({
          ext: `${ext}`
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const result = await response.json()
      await axios.put(result.uploadUri , tempFile , {
        headers: {
          'Content-Type': `${ext}`,
          'x-goog-acl': 'public-read'
        }
      }).then(e => { 
        setState({ logo: result.downloadUri })
      })
    }
  }
  if (user.role === 'Staff') {
    return ('Cannot Access')
  }
  const handleStartDate = (e) => {
    e.preventDefault()
    const dateStart = e.target.value
    console.log(e.target.value);
    if(moment(dateStart).isAfter(endDate)){
      setTimeDate(true)
    } else {
      setTimeDate(false)
    }
    setStartDate(dateStart)
  }
  const handleEndDate = (e) => {
    const dateEnd = e.target.value
    if(moment(dateEnd).isBefore(startDate)){
      setTimeDate(true)
    } else {
      setTimeDate(false)
    }
    setEndDate(dateEnd)
  }
  return (
    <div>
      <Breadcrumb path="/events" titleLink="Event" titleTypo={state.eventNameEN} />
      <br />
      <Grid
        className={classes.layout}
      >
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit}>
            {/* <div className={classes.buttons}> */}
            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h4">Edit Event</Typography>
            {/* </div> */}
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography style={{ marginTop: '20px' }} variant="h6" gutterBottom>Detail</Typography>
            </Grid>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">Event Name</InputLabel>
                  <Input
                    autoFocus
                    value={state.eventNameEN}
                    id="eventNameEN"
                    name="eventNameEN"
                    onChange={e => setState({ eventNameEN: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel>Organizer</InputLabel>
                  <Select
                    id="organizer"
                    name="organizer"
                    onChange={e => setState({ organizer : e.target.value})}
                    value={state.organizer}
                  // style={{ width: '200px' }}
                    disabled={user.role !== 'Admin'}
                  >
                    {data2 ? data2.organizerUsers.map((row) => (
                      <MenuItem key={row._id} value={row._id}>{row.name}</MenuItem>
                    ))
                      : (<MenuItem value="placeholder">Placeholder</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel>Slug Event</InputLabel>
                  <Input
                    id="slugEvent"
                    value={state.slugEvent}
                    name="slugEvent"
                    onChange={e => setState({ slugEvent : e.target.value})}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel>Code Event</InputLabel>
                  <Input
                    id="codeEvent"
                    name="codeEvent"
                    value={state.codeEvent}
                    onChange={e => setState({codeEvent : e.target.value})}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel>Event Logo</InputLabel>
                  <Button variant="contained" size="small" component="label" className={classes.button} style={{ marginTop: '20px', marginLeft: '400px', width: '120px' }} startIcon={<CloudUploadIcon />}>
                    {file.name ? file.name : 'choose image'} 
                    <input
                      onChange={selectFile}
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      style={{ display: 'none' }}
                    />
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
            
            {/* <FormControl margin="normal" required>
          <InputLabel>Organizer</InputLabel>
          <Select
            value={state.organizer}
            id="organizer"
            name="organizer"
            onChange={e => setState({ organizer: e.target.value })}
          >
            {data ? data.organizerUsers.map(row => (
              <MenuItem value={row._id}>{row.name}</MenuItem>
            ))
              : []
            }
          </Select>
        </FormControl> */}
            {shirts.map((k, index) => (
              <div key={`${k.slug}-shirts`}>
                {console.log(k,'k')}
                <Typography style={{ marginTop: '20px', marginBottom: '10px' }} variant="h6" gutterBottom>Shirt{index + 1}</Typography>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="outlined-read-only-input"
                      label="Slug Shirt"
                      defaultValue={k.slug}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <TextField
                      id="outlined-read-only-input"
                      label="Shirt Type: "
                      defaultValue={k.type}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <TextField
                      style={{ height: '32px' }}
                      label="Shirt Image:"
                      defaultValue={k.image}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />
                  </Grid>
                  <Grid item xs={12} sm={1} style={{ marginTop: '20px' }}>
                    <Fab

                      variant="extended"
                      style={{ height: '32px' }}
                      size="small"
                      color="primary"
                      aria-label="add"
                      onClick={() => removeShirt(index)}
                    >
                      <DeleteIcon />
                    </Fab>
                  </Grid>
                </Grid>

              </div>
            ))}

            <Typography style={{ marginTop: '20px', marginBottom: '10px' }} variant="h6" gutterBottom>Shirt Detail Entry</Typography>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>Slug Shirt</InputLabel>
                <Input
                  autoFocus
                  id="slugShirt"
                  name="slugShirt"
                  value={state.slugShirt}
                  onChange={e => setSlugShirt(e.target.value)}
                />
                {console.log(slugShirt, 'slugShirt')}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>Shirt Type</InputLabel>
                <Input

                  autoFocus
                  id="typeShirt"
                  name="typeShirt"
                  value={state.typeShirt}
                  onChange={e => setTypeShirt(e.target.value)}

                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>Shirt Image</InputLabel>
                <Input
                  autoFocus
                  id="imageShirt"
                  name="imageShirt"
                  value={state.imageShirt}
                  onChange={e => setImageShirt(e.target.value)}

                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="add"
                onClick={addShirt}
              >

                <AddIcon />
                Add Shirt

              </Fab>
            </Grid>

            {tickets.map((k, index) => (
              <div key={`${k.ticketType}`}>
                {console.log(k,'ticketType')}
                <Typography style={{ marginTop: '20px', marginBottom: '10px' }} variant="h6" gutterBottom>Ticket{index + 1}</Typography>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="outlined-read-only-input"
                      label="ticketType"
                      defaultValue={k.ticketType}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <TextField
                      id="outlined-read-only-input"
                      label="startDate: "
                      defaultValue={k.startDate}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                   <Grid item xs={12} sm={4}>

                    <TextField
                      id="outlined-read-only-input"
                      label="endDate: "
                      defaultValue={k.endDate}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />
                  </Grid>

                  <Grid item xs={12} sm={1} style={{ marginTop: '20px' }}>
                    <Fab

                      variant="extended"
                      style={{ height: '32px' }}
                      size="small"
                      color="primary"
                      aria-label="add"
                      onClick={() => removeTicket(index)}
                    >
                      <DeleteIcon />
                    </Fab>
                  </Grid>
                </Grid>

              </div>
            ))}

            <Typography style={{ marginTop: '20px', marginBottom: '10px' }} variant="h6" gutterBottom>Ticket</Typography>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>ticket type</InputLabel>
                <Input
                  autoFocus
                  id="ticketType"
                  value={state.ticketType}
                  name="ticketType"
                  onChange={e => setTicketType(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>Start Date</InputLabel>
                <Input

                  autoFocus
                  id="startDate"
                  name="startDate"
                  type="date"
                  onChange={handleStartDate}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>End Date</InputLabel>
                <Input

                  autoFocus
                  id="endDate"
                  name="endDate"
                  type="date"
                  onChange={handleEndDate}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="add"
                disabled = {timeDate}
                onClick={addTicketType}
              >

                <AddIcon />
                Add Ticket Type

              </Fab>
            </Grid>

            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom>Print</Typography>
            <Grid

              container
              spacing={3}
            >
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={state.print}
                        onChange={e => setState({ print: e.target.checked })}
                      />
              )}
                    label="Print"
                  />
              </Grid>
            </Grid>
            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom> Search Modes</Typography>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state.QRSearch}
                      onChange={e => setState({ QRSearch: e.target.checked })}
                    />
            )}
                  label="QR Code"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state.IDSearch}
                      onChange={e => setState({ IDSearch: e.target.checked })}
                    />
          )}
                  label="ID Card"
                />
              </Grid>
            </Grid>
            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom>Face Detection</Typography>
            <Grid
              container
              spacing={3}
            >
                
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state.uploadFace}
                      onChange={e => setState({ uploadFace: e.target.checked })}
                    />
          )}
                  label="From Upload"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state.isFaceCheckin}
                      onChange={e => setState({ isFaceCheckin: e.target.checked })}
                    />
          )}
                  label="FaceCheckin"
                />
                { state.IDSearch && (
                <div>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={state.IDFace}
                        onChange={e => setState({ IDFace: e.target.checked })}
                      />
            )}
                    label="ID Card"
                  />
                </div>
                )}
              </Grid>

            </Grid>
            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom> Self-Checkin</Typography>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state.selfCheckin || ''}
                      onChange={e => setState({ selfCheckin: e.target.checked })}
                    />
            )}
                  label="Self-Checkin"
                />
              </Grid>
            </Grid>
            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom> Others</Typography>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state.isShirtImage}
                      onChange={e => setState({ isShirtImage: e.target.checked })}
                    />
            )}
                  label="Shirt-Image"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state.autoPickup || ''}
                      onChange={e => setState({ autoPickup: e.target.checked })}
                    />
            )}
                  label="autoPickup"
                />
              </Grid>
            </Grid>
            <div className={classes.buttons}>
              <Button
                style={{ marginTop: '20px', marginBottom: '20px' }}
                type="submit"
        // fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
              >
            Submit
              </Button>
            </div>
            <Dialog
              open={state.dialogue}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Event Created</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
            Event has been edited.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
            Close
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </Paper>
      </Grid>
    </div>

  )
}

export default EditEvent
