/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useEffect } from 'react'
import {
  FormControl, InputLabel, Input, Button, Typography, Grid, makeStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Switch, Paper,
} from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { useMultiState } from '../hooks'
import editUserMutation from '../graphql/mutation/updateUserById'
import setPasswordMutation from '../graphql/mutation/setPassword'
import userByIdQuery from '../graphql/query/userById'

import AuthContext from '../contexts/AuthContext'
import Breadcrumb from '../components/BreadcrumbLayout'

const initialState = {
  userName: '',
  loginName: '',
  pass1: '',
  pass2: '',
  role: 'Organizer',
  readOnly: true,
  disablePassword: false,
}
const CreateEvent = () => {
  const { userId } = useParams()
  const [state, setState] = useMultiState(initialState)
  const { data } = useQuery(userByIdQuery, { variables: { id: userId } })
  const [editUser] = useMutation(editUserMutation)
  const [setPassword] = useMutation(setPasswordMutation)
  const history = useHistory()

  useEffect(() => {
    if (data) {
      setState(
        {
          userName: data.userId.name,
          loginName: data.userId.username,
          role: data.userId.role,
          readOnly: false,
        }
      )
    }
  }, [data, setState])

  const [open, setOpen] = useState(false)
  const [passwordOpen, setPasswordOpen] = useState(false)

  const { user } = useContext(AuthContext)
  const handleClose = () => {
    setOpen(false)
    setPasswordOpen(false)
    window.location.reload()
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (state.pass1 === state.pass2) {
        editUser(
          {
            variables: {
              record: {
                _id: userId,
                name: state.userName,
                username: state.loginName,
                role: state.role,
              },
            },
          }
        ).then((result) => {
          if (!state.disablePassword) {
            setPassword(
              {
                variables: {
                  id: result.data.updateUser.recordId,
                  password: state.pass1,
                },
              }
            )
          }
        }
        ).then(() => {
          setOpen(true)
          history.push('/users')
        })
      } else {
        setPasswordOpen(true)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  }))
  const roles = ['Organizer', 'Staff']
  const classes = useStyles()
  if (user.role === 'Staff') {
    return ('Cannot Access')
  }
  if (user.role === 'Admin') {
    roles.push('Admin')
  }
  return (
    <React.Fragment>
      <Breadcrumb path="/users" titleLink="User" titleTypo={state.userName} />
      <br />
      <Grid className={classes.layout}>
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit} autoComplete="nope">
            <Input style={{ display: 'none' }} />
            <Input type="password" style={{ display: 'none' }} />

            <Typography variant="h4" style={{ marginTop: '20px', marginBottom: '20px' }}>Edit User</Typography>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography style={{ marginTop: '20px' }} variant="h6" gutterBottom>Detail</Typography>
            </Grid>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">organizer Name</InputLabel>
                  <Input
                    autoFocus
                    id="organizerName"
                    name="organizerName"
                    value={state.userName}
                    onChange={e => setState({ userName: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">Username</InputLabel>
                  <Input
                    autoFocus
                    autoComplete="nope"
                    id="loginName"
                    name="loginName"
                    value={state.loginName}
                    onChange={e => setState({ loginName: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={state.disablePassword}
                      onChange={e => setState({ disablePassword: e.target.checked })}
                    />
            )}
                  label="Edit Password"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                { state.disablePassword ? (
                  <FormControl margin="normal" fullWidth required>

                    <InputLabel htmlFor="age-helper">Password</InputLabel>
                    <Input
                      disabled={!state.disablePassword}
                      autoComplete="nope"
                      label="Password"
                      className={classes.textField}
                      type="password"
                      onChange={e => setState({ pass1: e.target.value })}
                      readOnly={state.readOnly}
                    />
                  </FormControl>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                { state.disablePassword ? (
                  <FormControl margin="normal" fullWidth required>
                    <InputLabel htmlFor="age-helper">Confirm Password</InputLabel>
                    <Input
                      disabled={!state.disablePassword}
                      label="Confirmed Password"
                      className={classes.textField}
                      type="password"
                      onChange={e => setState({ pass2: e.target.value })}
                      readOnly={state.readOnly}
                    />
                  </FormControl>
                ) : null}
              </Grid>

              {/* <FormControl className={classes.formControl} required>
              <InputLabel htmlFor="age-helper">Role</InputLabel>
              <Select
                select
                value={state.role}
                onChange={e => setState({ role: e.target.value })}
                inputProps={{
                  name: 'role',
                  id: 'role-helper',
                }}
              >
                {
            roles.map(row => (
              <MenuItem value={row}>{row}</MenuItem>
            )
            )}
              </Select>
            </FormControl> */}
            </Grid>
            <div className={classes.buttons}>
              <Button
                style={{ marginTop: '20px', marginBottom: '20px' }}
                type="submit"
        // fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
              >
            Submit
              </Button>
            </div>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">User Created</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {state.role} {state.userName} has been created.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
            Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={passwordOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">User Created</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
            Passwords do not match
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
            Close
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </Paper>
      </Grid>
    </React.Fragment>
  )
}

export default CreateEvent
