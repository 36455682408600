import gql from 'graphql-tag'

export default gql`query users($filter: FilterFindManyUserInput){
    users(filter: $filter){
      name
      username
      role
      _id
    }
  }`
