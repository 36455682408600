import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import { LayoutContext } from './Root'

const useStyles = makeStyles(({
  breakpoints, palette, spacing, transitions,
}) => ({
  root: {
    borderTop: '1px solid',
    borderColor: palette.divider,
    padding: spacing(2),
    [breakpoints.up('sm')]: {
      padding: spacing(3),
    },
    transition: transitions.create(['margin'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    textAlign: 'center',
  },
}))

const Footer = ({
  className,
  component: Component,
  style,
  zeroPadding,
  ...props
}) => {
  const classes = useStyles()
  const ctx = useContext(LayoutContext)
  const {
    navVariant,
    navWidth,
    collapsible,
    collapsed,
    collapsedWidth,
    footerShrink,
    open,
    navAnchor,
  } = ctx
  const getMargin = () => {
    if (navAnchor !== 'left' || !footerShrink) return 0
    if (navVariant === 'persistent' && open) {
      // open is effect only when
      // navVariant === 'persistent' ||
      // navVariant === 'temporary'
      return navWidth
    }
    if (navVariant === 'permanent') {
      if (collapsible) {
        if (collapsed) return collapsedWidth
        return navWidth
      }
      return navWidth
    }
    return 0
  }
  return (
    <Component
      {...props}
      className={`${className} ${classes.root}`}
      style={{
        ...(zeroPadding && { padding: 0 }),
        ...style,
        marginLeft: getMargin(),
      }}
    />
  )
}
Footer.propTypes = {
  className: PropTypes.string,
  component: PropTypes.elementType,
  style: PropTypes.shape({}),
  zeroPadding: PropTypes.bool,
}
Footer.defaultProps = {
  className: '',
  component: 'footer',
  style: {},
  zeroPadding: false,
}

export default Footer
