import React, { Fragment, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Drawer, Button, IconButton, Grow, List, ListSubheader, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Switch, Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { EmojiObjects as EmojiObjectsIcon } from '@material-ui/icons'

import ThemeContext from '../../contexts/ThemeContext'

import { LayoutContext } from './Root'

const useStyles = makeStyles(({
  breakpoints,
  transitions,
  palette,
  spacing,
  zIndex,
  shadows,
}) => ({
  root: {},
  container: {
    overflow: 'hidden',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    transition: transitions.create(['width'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  collapseButton: {
    backgroundColor: palette.grey[50],
    textAlign: 'center',
    borderRadius: 0,
    borderTop: '1px solid',
    borderColor: 'rgba(0,0,0,0.12)',
    [breakpoints.up('sm')]: {
      minHeight: 40,
    },
  },
  closeButton: {
    position: 'absolute',
    bottom: spacing(2),
    zIndex: zIndex.modal + 1,
    background: palette.background.paper,
    boxShadow: shadows[2],
    // '@media (hover: none)': {
    //   backgroundColor: palette.background.paper,
    // },
  },
}))

const Nav = ({
  className,
  component: Component,
  header,
  children,
  collapsedIcon,
  ...props
}) => {
  const classes = useStyles()
  const { theme, toggleTheme } = useContext(ThemeContext)
  const ctx = useContext(LayoutContext)
  const {
    open,
    setOpen,
    navVariant,
    navAnchor,
    navWidth,
    collapsedWidth,
    collapsible,
    collapsed,
    setCollapse,
  } = ctx
  const getWidth = () => {
    if (collapsible && collapsed) return collapsedWidth
    return navWidth
  }
  const shouldRenderButton = collapsible && collapsedIcon
  const contentRef = useRef(null)
  return (
    <Fragment>
      <Drawer
        {...props}
        className={`${className} ${classes.root}`}
        open={open}
        onClose={setOpen}
        variant={navVariant}
        anchor={navAnchor}
      >
        <div className={classes.container} style={{ width: getWidth() }}>
          {typeof header === 'function' ? header(ctx) : header}
          <Divider />
          <div ref={contentRef} className={classes.content}>
            {typeof children === 'function' ? children(ctx) : children}
          </div>
          <Divider />
          <List subheader={<ListSubheader>Settings</ListSubheader>} dense>
            <ListItem>
              <ListItemIcon>
                <EmojiObjectsIcon />
              </ListItemIcon>
              <ListItemText id="switch-theme" primary="Dark theme" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={() => toggleTheme()}
                  checked={theme === 'dark'}
                  inputProps={{ 'aria-labelledby': 'switch-theme' }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          {shouldRenderButton && (
            <Button
              className={classes.collapseButton}
              fullWidth
              onClick={setCollapse}
            >
              {collapsed
                ? collapsedIcon.active
                : collapsedIcon.inactive || collapsedIcon.active}
            </Button>
          )}
        </div>
      </Drawer>
      <Grow in={open && navVariant === 'temporary' && !!collapsedIcon}>
        <IconButton
          className={classes.closeButton}
          style={{ left: navWidth + 16 }}
          onClick={setOpen}
        >
          {collapsedIcon.inactive}
        </IconButton>
      </Grow>
    </Fragment>
  )
}
Nav.propTypes = {
  className: PropTypes.string,
  component: PropTypes.elementType,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  collapsedIcon: PropTypes.shape({
    inactive: PropTypes.node.isRequired,
    active: PropTypes.node,
  }),
}
Nav.defaultProps = {
  className: '',
  component: 'div',
  header: null,
  collapsedIcon: null,
}

export default Nav
