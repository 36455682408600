import gql from 'graphql-tag'

export default gql`query userId($id:MongoID!){
    userId(_id:$id){
      _id
      username
      name
      role
    }
  }`
