import React, {
    useState, useCallback, Fragment,
  } from 'react'
  import { Redirect, useParams } from 'react-router-dom'
  import { CircularProgress,DialogActions , DialogContent ,Dialog,DialogTitle,DialogContentText } from '@material-ui/core'
  import Promise from 'bluebird'
  import {
    Typography, Table, TableHead, TableRow, TableCell, TableBody,
    Toolbar, Button, Grid, Select, MenuItem,
    Card, CardContent, LinearProgress, Box, Chip,
  } from '@material-ui/core'
  import TripOrigin from '@material-ui/icons/TripOrigin'
  import { makeStyles, withStyles } from '@material-ui/styles'
  import { useMutation, useQuery } from '@apollo/react-hooks'
  import moment from 'moment'
  import XLSX from 'xlsx'
  
  import Chart from 'react-apexcharts'
  import _sortBy from 'lodash/sortBy'
  import _get from 'lodash/get'
  // import _reverse from 'lodash/reverse'
  import _groupBy from 'lodash/groupBy'
  import JSZip from 'jszip'
  import { saveAs } from 'file-saver'
  
  import Loading from '../components/Loading'
  import participantReportQuery from '../graphql/query/participantReport'
  import downloadFaceMutation from '../graphql/mutation/downloadFace'
  import activitiesCountQuery from '../graphql/query/activitiesCount'
  import activitiesTicketTypeQuery from '../graphql/query/activitiesTicketTypeCount'
  import activitiesQuery from '../graphql/type-composers/activity'
  import eventByIdQuery from '../graphql/query/eventById'
//   import EventContext from '../contexts/EventContext'
  
  const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
    title: {
      flex: '1 1 100%',
    },
    card: {
      margin: '20px',
    },
    table: {
      width: '100%',
      overflowX: 'auto',
      marginBottom: spacing(1),
    },
    div2: {
      [breakpoints.up(960)]: {
        marginBottom: '4em',
        flexDirection: 'row',
      },
      [breakpoints.down(960)]: {
        flexDirection: 'column',
      },
    },
    div2Card: {
      margin: '20px',
      [breakpoints.up(960)]: {
        height: '100%',
      },
    },
    statusLabel: {
      width: '72px',
      fontWeight: 'bold',
    },
    seeMoreBtn: {
      // [breakpoints.up(960)]: {
      //   transform: 'translateY(100%)',
      // },
    },
    gShirt: {
      marginTop: '20px',
      [breakpoints.up(960)]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      [breakpoints.down(960)]: {
        flexDirection: 'column',
      },
    },
    gDonut: {
      [breakpoints.up(960)]: {
        flex: 2,
        display: 'flex',
        justifyContent: 'center',
      },
      [breakpoints.down(960)]: {
        flex: 1,
        margin: 'auto',
        marginBottom: '20px',
      },
    },
    gTable: {
      [breakpoints.up(960)]: {
        flex: 3,
      },
      [breakpoints.down(960)]: {
        flex: 1,
      },
    },
    top: {
      color: '#1a90ff',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  
  }))
  
  const StatsPage = () => {
    const classes = useStyles()
    const [exporting, setExporting] = useState(false)
    const [getLoading, setLoading] = useState(false)
    const [progress, setProgress] = useState(0)
    const [selectedCheckinChartDataDate, setSelectedCheckinChartDataDate] = useState('')
    // const { event } = useContext(EventContext)
    const { eventId } = useParams()
    const { data, loading } = useQuery(eventByIdQuery, { variables: { _id: eventId } })
    const activitiesCount = useQuery(activitiesCountQuery,
      {
        variables: { eventId, interval: 20 },
        pollInterval: 20000,
        fetchPolicy: 'network-only',
      })
    const activitiesTicketType = useQuery(activitiesTicketTypeQuery,
      {
        variables: { eventId },
        pollInterval: 12000,
        fetchPolicy: 'network-only',
      })
    const activities = useQuery(activitiesQuery,
      {
        variables: { eventId },
        pollInterval: 7000,
        fetchPolicy: 'network-only',
      })
    const participantReport = useQuery(participantReportQuery, { fetchPolicy: 'network-only', skip: true })
    const [downloadFace] = useMutation(downloadFaceMutation)
    const colorList = ['#E5BC30', '#E96583', '#00B2B2']
    const handleChangeCheckInDate = (e) => {
      setSelectedCheckinChartDataDate(e.target.value)
    }
  
    const handleExport = useCallback(
      async () => {
        try {
          setExporting(true)
          const participantReportResult = await participantReport.refetch({ eventId })
          const workbook = XLSX.utils.book_new()
          const worksheet = XLSX.utils.json_to_sheet(participantReportResult.data.participantReport.map(({ event: { name: eventName }, options, ...participant }) => ({ ...participant, checkin_date: participant.timestamp? moment(participant.timestamp).format('DD/MM/YYYY'): '', eventName, ...options })))
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Participants')
          XLSX.writeFile(workbook, `participants-${moment().format('YYYYMMDDHHmmSS')}.xlsx`)
        } catch (err) {
          console.error(err)
        } finally {
          setExporting(false)
        }
      },
      [setExporting, eventId, participantReport]
    )
    function CircularProgressWithLabel(props) {
      return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" {...props} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
      );
    }
    const saveZip = async(data, pageCount, PAGESIZE) => {
      const zip = new JSZip()
      let img = zip.folder("ExportFace")
      const batches = await Promise.each([...new Array(pageCount)], async(_, page) => {
        console.log(page, 'page');
        if(page !== 0){
          data = data.slice(PAGESIZE)
        }
        const dataRow = data.slice(0, PAGESIZE)
        const promises = await dataRow.map((url, index) => fetch(`${url.photo}`).then(r => r.blob().then(e => {
              img.file(`${url.bib}.jpeg`, e)
        })))
        const code = await Promise.all(promises)
        // setProgress(((total - data.length) * 100) / total)
        if(code) {
          return code
        }
      })
      
  
      if(batches) {
          await zip.generateAsync({type: "blob"}).then(function(content){
              saveAs(content, `ExportFaceAll.zip`)
          })
          
      }
    } 
    const handleFaceExport = useCallback(
      async () => {
        try {
          setLoading(true)
          const downloadFaceExportResult = await downloadFace({variables: { eventId }})
          const total = downloadFaceExportResult.data.downloadFace.length
          const PAGESIZE = 20
          let PAGECOUNT = Math.ceil(total / PAGESIZE)
          let halfCount = 0
          if(total > 4000) {
            halfCount =  Math.ceil(total / 2)
          }
          let data = downloadFaceExportResult.data.downloadFace
          let data1 = []
          let data2 = []
          let pageCount1 = 0
          let pageCount2 = 0
          if(halfCount !== 0){
            data1 = downloadFaceExportResult.data.downloadFace.slice(0, halfCount)
            data2 = downloadFaceExportResult.data.downloadFace.slice(halfCount, total)
            pageCount1 = data1.length
            pageCount2 = data2.length
            await saveZip(data1, pageCount1, PAGESIZE)
            await saveZip(data2, pageCount2, PAGESIZE)
          }
          else {
            await saveZip(data, PAGECOUNT, PAGESIZE)
          }
          // data = data.slice(5000)
          // console.log(data);
          
        
        } catch (err) {
          console.error(err)
        }
        setProgress(100)
      },
      [setLoading,eventId, downloadFace]
    )
  
    const formateTimeTicket = (time) => {
      const timex = moment(time).format('DD/MM/YYYY hh:mm:ss')
      return timex
    }
  
    let checkinChartDataDaily = {
      options: {},
      series: [],
    }
    const daily = _get(activitiesCount, 'data.activitiesCountByTime')
    const ticketTypes = _get(activitiesTicketType, 'data.activitiesCountByTicketType')
  
    let activitiesDateList = []
    if (daily && daily.length > 0) {
      activitiesDateList = [...new Set(daily.map((x) => {
        if (x.time == null) return null
        return x.time.split('T')[0]
      }).filter(Boolean))]
    }
    const getDailyList = (selectedDate) => {
      const selectDateList = daily.filter(ea => (ea.time != null) && ea.time.split('T')[0] === selectedDate)
      const selectDx = selectDateList.map(sea => ({
        time: new Date(sea.time).getHours(),
        count: sea.count,
      }))
      const checkin24hrs = Array.from({ length: 24 }, (v, i) => i).map((ea) => {
        const countx = selectDx.filter(ef => ef.time === ea)
        return countx.length > 0 ? countx[0].count : 0
      })
      return checkin24hrs
    }
    if (selectedCheckinChartDataDate === '' && daily && daily.length > 0 && activitiesDateList.length > 0) {
      setSelectedCheckinChartDataDate(activitiesDateList[0])
    }
  
    checkinChartDataDaily = {
      options: {
        chart: {
          id: 'bar',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            startingShape: 'rounded',
            endingShape: 'rounded',
            columnWidth: 25,
            barHeight: 24,
            distributed: false,
            rangeBarOverlap: true,
            rangeBarGroupRows: false,
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: undefined,
              }],
              backgroundBarColors: Array(24).fill('#efefef'),
              backgroundBarOpacity: 1,
              backgroundBarRadius: 5,
            },
          },
        },
        xaxis: {
          categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
          // categories,
          axisBorder: {
            show: false,
            color: '#78909C',
            offsetX: 0,
            offsetY: 0,
          },
          lines: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          title: { text: 'Time (Hr)' },
          labels: {
            rotate: 0,
            rotateAlways: false,
            style: {
              // colors: '#ddd',
              fontSize: '12px',
              // colors: '#B1B1B1',
            },
          },
        },
        yaxis: {
          min: 0,
          max: selectedCheckinChartDataDate ? Math.max(...getDailyList(selectedCheckinChartDataDate)) : 1,
          labels: {
            style: {
              fontSize: '16px',
              // colors: '#000',
              // colors: '#B1B1B1',
            },
            formatter(val) {
              return val.toFixed(0)
            },
          },
        },
        legend: {
          show: false,
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      series: [{
        name: 'amount',
        data: selectedCheckinChartDataDate ? getDailyList(selectedCheckinChartDataDate) : Array(24).fill(0),
        color: '#EB5757',
      }],
    }
  
    let shirts = []
    const activitiesCountByShirt = _get(activitiesCount, 'data.activitiesCountByShirt')
    if (activitiesCountByShirt) {
      const shirtTypeGroup = _groupBy(activitiesCountByShirt, 'shirtType')
      const keys = Object.keys(shirtTypeGroup)
      shirts = keys.map((key) => {
        const shirtsx = _sortBy(shirtTypeGroup[key], 'shirtSize')
        const labels = shirtsx.map(s => s.shirtSize)
        const data = shirtsx.map(s => s.count)
        const chartData = {
          options: {
            colors: colorList,
            labels,
            dataLabels: {
              enabled: true,
            },
            plotOptions: {
              pie: {
                // customScale: 0.8,
                hover: {
                  filter: {
                    type: 'none',
                  },
                },
                donut: {
                  size: '50%',
                  labels: {
                    show: true,
                    total: {
                      // showAlways: true,
                      show: true,
                      label: key,
                      fontSize: '15px',
                      fontWeight: 'bold',
  
                      // formatter: () => {
                      //     return 'ตัว'
                      //   }
                      formatter(w) {
                        return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                      },
  
                    },
                  },
                },
                roseType: true,
              },
            },
            legend: {
              show: false,
              formatter(val, opts) {
                return `${val} -> ${opts.w.globals.series[opts.seriesIndex]}`
              },
            },
            stroke: {
              width: 5,
            },
          },
          series: data,
        }
  
        return {
          shirtType: key,
          chartData,
          tableData: shirtsx,
        }
      })
    }
    // }, [activitiesCount])
    // console.log(shirts)
    // console.log(event)
    if (!eventId) {
      return <Redirect to="/events" />
    }
    const allCheckin = activitiesCount.data && activitiesCount.data.activitiesCount.filter(activity => !!activity.lastActivity).reduce((prev, cur) => (prev + cur.count), 0)
    const totalParticipants = activitiesCount.data && activitiesCount.data.activitiesCount.reduce((prev, cur) => (prev + cur.count), 0)
    const remainParticipants = activitiesCount.data && activitiesCount.data.activitiesCount.filter(activity => !activity.lastActivity).reduce((prev, cur) => (prev + cur.count), 0)
    // if (activitiesCount.data) {
    //   console.log(activities.data)
    // }
  
    // const registerTypesData = {
  
    //   series: [{
    //     name: 'PRODUCT A',
    //     data: [44, 55, 41],
    //   }, {
    //     name: 'PRODUCT B',
    //     data: [13, 23, 20],
    //   }, {
    //     name: 'PRODUCT C',
    //     data: [11, 17, 15],
    //   }],
    //   options: {
    //     colors: colorList,
    //     dataLabels: {
    //       enabled: false,
    //     },
    //     chart: {
    //       // zoom: {
    //       //   enabled: false,
    //       // },
    //       toolbar: {
    //         show: false,
    //       },
    //       type: 'bar',
    //       height: 600,
    //       stacked: true,
    //     },
    //     responsive: [{
    //       breakpoint: 480,
    //       options: {
    //         legend: {
    //           position: 'bottom',
    //           offsetX: -10,
    //           offsetY: 0,
    //         },
    //       },
    //     }],
    //     plotOptions: {
    //       bar: {
    //         horizontal: false,
    //         borderRadius: 10,
    //         startingShape: 'rounded',
    //         endingShape: 'rounded',
    //         columnWidth: 25,
    //         barHeight: 24,
    //         distributed: false,
    //         rangeBarOverlap: true,
    //         rangeBarGroupRows: false,
    //         colors: {
    //           ranges: [{
    //             from: 0,
    //             to: 0,
    //             color: undefined,
    //           }],
    //           backgroundBarColors: Array(24).fill('#efefef'),
    //           backgroundBarOpacity: 1,
    //           backgroundBarRadius: 10,
    //         },
    //       },
    //     },
    //     xaxis: {
    //       type: 'category',
    //       categories: ['Draft', 'Submitted', 'Submitted & Paid'],
    //       labels: {
    //         rotate: 0,
    //         rotateAlways: false,
    //       },
    //       lines: {
    //         show: false,
    //       },
    //       axisTicks: {
    //         show: false,
    //       },
    //       axisBorder: {
    //         show: false,
    //         color: '#78909C',
    //         offsetX: 0,
    //         offsetY: 0,
    //       },
    //     },
    //     legend: {
    //       show: false,
    //     },
    //     grid: {
    //       yaxis: {
    //         lines: {
    //           show: false,
    //         },
    //       },
    //     },
    //     fill: {
    //       opacity: 1,
    //     },
    //   },
    // }
  
    const BorderLinearProgress = withStyles(theme => ({
      root: {
        width: 600,
        height: 20,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      },
      bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
      },
    }))(LinearProgress)
  
    const getColorStatus = (status) => {
      let color1
      switch (status) {
        case 'checkin':
          color1 = {
            color: '#E5BC3D',
            bg: '#FCF7E7',
          }
          break
        case 'pickup':
          color1 = {
            color: '#00B2B2',
            bg: '#E0F6F6',
          }
          break
        default:
          color1 = {
            color: '#000',
            bg: '#000',
          }
      }
      return color1
    }
  
    if (activitiesCount.loading) {
      return (<Loading />)
    }if (loading) {
      return (<Loading />)
    } 
    return (
      <Fragment>
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Toolbar>
              <Typography className={classes.title} variant="h5">Participants</Typography>
              {!activitiesCount.loading && data?.eventId?.isFaceCheckin ? (
                <Button style={{margin: 10}} variant="contained" color="secondary" onClick={handleFaceExport} disabled={getLoading}>
                  {getLoading ? 'Loading ...' : 'Export Face'}
                </Button>
              ) : null}
              {!activitiesCount.loading ? (
                <Button variant="contained" color="secondary" onClick={handleExport} disabled={exporting}>
                  {exporting ? 'Exporting ...' : 'Export'}
                </Button>
              ) : null}
            </Toolbar>
            <Dialog
              open={getLoading}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className={classes.layout}
            >
              <DialogTitle id="alert-dialog-title">Exporting</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {(progress === 100) ? 'Finished' : 'Exporting'}
                </DialogContentText>
                {(progress < 100) ? <CircularProgressWithLabel variant="indeterminate" value={progress} /> : ''}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setLoading(false)} color="primary" autoFocus disabled={!(progress === 100)}>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
  
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid>
                <Typography className={classes.title} variant="h1">
                  {allCheckin || 'Loading..'}
                </Typography>
              </Grid>
              <Grid>
                <BorderLinearProgress variant="determinate" value={Number(((allCheckin / totalParticipants) * 100).toFixed(2))} />
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid>
                  <Typography className={classes.title} variant="h5">
                    {allCheckin ? ((allCheckin / totalParticipants) * 100).toFixed(2) : 0}% of {totalParticipants}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.title} variant="h5">
                    remains {remainParticipants}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
  
        <Grid
          container
          spacing={1}
          className={classes.div2}
          alignItems="stretch"
        >
          <Grid item sm={12} md={6}>
            <Card className={classes.div2Card} variant="outlined">
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid>
                    <Typography className={classes.title} variant="h5">
                      Participants
                    </Typography>
                  </Grid>
                  <Grid>
                    <Select
                      labelid="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCheckinChartDataDate}
                      onChange={e => handleChangeCheckInDate(e)}
                    >
                      {
                        activitiesDateList.map(ea => (
                          <MenuItem key={ea} value={ea}>{ea}</MenuItem>
                        ))
                      }
                    </Select>
                  </Grid>
                </Grid>
  
                <Chart
                  options={checkinChartDataDaily.options}
                  series={checkinChartDataDaily.series}
                  type="bar"
                  width="100%"
                  style={{
                    // background: 'white',
                    margin: 30,
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={6}>
            <Card className={classes.div2Card} variant="outlined">
              <CardContent style={{ height: '100%' }}>
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ height: '100%' }}>
                  <Box>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid>
                        <Typography className={classes.title} variant="h5">
                          Ticket
                        </Typography>
                      </Grid>
                    </Grid>
                    <Table
                      className={classes.table}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Time</TableCell>
                          <TableCell>Bib</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          activities.data && activities.data.activitiesPagination.items.map(item => (
                            <TableRow key={item._id}>
                              <TableCell>{formateTimeTicket(item.createdAt)}</TableCell>
                              <TableCell>{item.participant.bib}</TableCell>
                              <TableCell>{`${item.participant.firstname} ${item.participant.lastname}`}</TableCell>
                              <TableCell><Chip className={classes.statusLabel} style={{ backgroundColor: getColorStatus(item.status).bg, color: getColorStatus(item.status).color, maxWidth: '100%', minWidth: '100px' }} label={item.status} /></TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </Box>
  
                  {/* <Box
                    className={classes.seeMoreBtn}
                  >
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" fullWidth>see more</Button>
                    </Grid>
                  </Box> */}
                </Box>
  
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {shirts && _sortBy(shirts, 'shirtType').map(s => (
          <Card key={s.shirtType} className={classes.card} variant="outlined">
            <CardContent>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid>
                  <Typography className={classes.title} variant="h5">
                  Shirts type of {s.shirtType}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.gShirt}
              >
                <Grid className={classes.gDonut}>
                  <Chart
                    type="donut"
                    options={s.chartData.options}
                    series={s.chartData.series}
                    width={300}
                  />
                </Grid>
                <Grid className={classes.gTable}>
                  <Table
                    className={classes.table}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Shirt name</TableCell>
                        <TableCell align="right">Count</TableCell>
                        <TableCell align="right">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {s.tableData && s.tableData.map((t, i) => (
                        <TableRow key={t.shirtSize}>
                          <TableCell><TripOrigin style={{ transform: 'translateY(25%)', color: colorList[i] }} /> {t.shirtSize}</TableCell>
                          <TableCell align="right">{t.count}</TableCell>
                          <TableCell align="right">{t.stock}</TableCell>
                        </TableRow>
                      ))
  
                      }
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )
        )}
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid>
                <Typography className={classes.title} variant="h5">
                  Registrations type
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.gShirt}
            >
              {/* <Grid className={classes.gDonut}>
                <Chart
                  options={registerTypesData.options}
                  series={registerTypesData.series}
                  type="bar"
                  width="100%"
                  style={{
                    background: 'white',
                    margin: 30,
                  }}
                />
              </Grid> */}
              <Grid className={classes.gTable}>
                <Table
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Ticket Type</TableCell>
                      <TableCell align="right">Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ticketTypes && ticketTypes.map(t => (
                      <TableRow key={t._id}>
                        <TableCell>{t._id}</TableCell>
                        <TableCell align="right">{t.count}</TableCell>
                        {console.log(t,'t')}
                      </TableRow>
                    ))
  
                    }
                    {/* <TableRow>
                      <TableCell><TripOrigin style={{ transform: 'translateY(25%)', color: colorList[0] }} /> AAA</TableCell>
                      <TableCell align="right">BBB</TableCell>
                      <TableCell align="right">CCC</TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Fragment>
    )
  }
  
  export default StatsPage
  