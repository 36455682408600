/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
import React, { useContext } from 'react'
import {
  Typography, Paper, Button, ButtonGroup, Grid, Fab
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useQuery } from '@apollo/react-hooks'
import PersonAddTwoToneIcon from '@material-ui/icons/PersonAddTwoTone'
import { Link as Linkreact } from 'react-router-dom'

import usersQuery from '../graphql/query/users'
import AuthContext from '../contexts/AuthContext'
import Breadcrumb from '../components/BreadcrumbLayout'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
}))

const UsersPage = () => {
  const { user } = useContext(AuthContext)
  const classes = useStyles
  let variables = {
    filter: {
      createdById: user._id
    }
  }
  if (user.role === 'Admin') {
    variables = {}
  }
  const users = useQuery(usersQuery, { variables })
  if (user.role === 'Staff') {
    return ('Cannot Access')
  }
  return (
    <div>
      <Breadcrumb titleTypo="Users" />
      <br />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
      >
        <Typography variant="h5">Users</Typography>
        <Fab variant="extended" to="/user/create" color="primary" style={{ height: '32px' }} component={Linkreact}>
          <PersonAddTwoToneIcon />
        Add User
        </Fab>
      </Grid>
      <Paper className={classes.root} style={{ marginTop: '10px' }}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User Name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.loading || users.data === undefined ? [] : users.data.users.map(row => (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">{row.name}</TableCell>
                <TableCell>{row.role ? row.role : 'Not Found'}</TableCell>
                <TableCell align="right">
                  <ButtonGroup color="primary">
                    <Button variant="contained" size="small" color="primary" to={`/user/${row._id}/edit`} component={Linkreact} className={classes.button}>
                      <EditIcon /> Edit
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}

export default UsersPage

