import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline } from '@material-ui/core'
import { ApolloProvider } from '@apollo/react-hooks'

import { ThemeProvider } from './contexts/ThemeContext'
import { AuthProvider } from './contexts/AuthContext'

import client from './apolloClient'

import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider>
      <CssBaseline />
      <AuthProvider>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </AuthProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// const onUpdate = () => {
//   // eslint-disable-next-line no-alert
//   alert('New version is available, Please close all tab and reload this page.')
// }

// serviceWorker.register({ onUpdate })
serviceWorker.unregister()
