import gql from 'graphql-tag'

export default gql`query participants($filter:FilterFindManyParticipantInput){
    participants(filter:$filter){
      _id
      eventId
      firstname
      lastname
      ageCategory
      displayName
      gender
      row
      bib
      lastActivity {
        status
      }
    }
  }`
