import { teal, deepOrange } from '@material-ui/core/colors'

export default {
  dark: {
    palette: {
      type: 'dark',
      primary: teal,
      secondary: deepOrange,
    },
  },
  light: {
    palette: {
      primary: teal,
      secondary: deepOrange,
    },
  },
}
