import React, { useContext, useEffect } from 'react'
import {
  FormControl, InputLabel, Input, Button, Typography, Grid, Select, MenuItem, makeStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper,
} from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useParams, useHistory } from 'react-router-dom'

// import eventByIdQuery from '../graphql/query/eventById'
import participantQuery from '../graphql/query/participant'
import updateParticipantMutation from '../graphql/mutation/updateParticipantById'
import AuthContext from '../contexts/AuthContext'
import { useMultiState } from '../hooks'
import Breadcrumb from '../components/BreadcrumbLayout'

const initialState = {
  firstname: '',
  lastname: '',
  ageCategory: '',
  bib: '',
  displayName: '',
  gender: '',
  row: '',
  status: '',
  nationality: '',
  nationalId: '',
  options: '{}',
  open: false,
}
const EditParticipant = () => {
  const { participant, eventId } = useParams()
  const { data } = useQuery(participantQuery, { variables: { filter: { _id: participant } } })
  const [updateParticipant] = useMutation(updateParticipantMutation)
  const [state, setState] = useMultiState(initialState)
  // const { eventdata } = useQuery(eventByIdQuery, { variables: { _id: eventId } })
  const { user } = useContext(AuthContext)
  const history = useHistory()
  useEffect(() => {
    if (data) {
      setState(data.participant)
      setState({ __typename: undefined })
    }
  }, [data, setState])
  const handleClose = async (e) => {
    e.preventDefault()
    setState({ open: false })
    history.push(`/event/${eventId}/participant`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      updateParticipant(
        {
          variables: {
            record: {
              firstname: state.firstname,
              lastname: state.lastname,
              ageCategory: state.ageCategory,
              bib: state.bib,
              displayName: state.displayName,
              gender: state.gender,
              row: state.row,
              status: state.status,
              nationality: state.nationality,
              nationalId: state.nationalId,
              options: state.options,
              _id: participant,
            },
          },
        }
      )
      setState({ open: true })
    } catch (err) {
      console.log(err)
    }
  }
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  }))
  const genderList = ['male', 'female']
  const classes = useStyles()
  if (user.role === 'Staff') {
    return ('Cannot Access')
  }
  return (
    <div>
      <Breadcrumb path={`/event/${eventId}/participant`} titleLink="Event" titleTypo={`${state.firstname} ${state.lastname}`} />
      <br />
      <Grid
        className={classes.layout}
        // alignItems="center"
      >
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit}>

            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h4">Edit Participant</Typography>
            <Grid
              item
              xs={12}
              sm={12}
            >
              <Typography style={{ marginTop: '20px' }} variant="h6" gutterBottom>Detail </Typography>
            </Grid>

            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} sm={12}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">Bib</InputLabel>
                  <Input
                    value={state.bib ? state.bib : ''}
                    autoFocus
                    id="bib"
                    name="bib"
                    onChange={e => setState({ bib: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">First Name</InputLabel>
                  <Input
                    value={state.firstname ? state.firstname : ''}
                    autoFocus
                    id="firstName"
                    name="firstName"
                    onChange={e => setState({ firstname: e.target.value })}
                  />

                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">Last Name</InputLabel>
                  <Input
                    value={state.lastname ? state.lastname : ''}
                    autoFocus
                    id="lastName"
                    name="lastName"
                    onChange={e => setState({ lastname: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl margin="normal" required>
                  <InputLabel>Display Name</InputLabel>
                  <Input
                    value={state.displayName ? state.displayName : ''}
                    autoFocus
                    id="displayName"
                    name="displayName"
                    onChange={e => setState({ displayName: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" required>
                  <InputLabel htmlFor="age-helper">Gender</InputLabel>
                  <Select
                    select
                    style={{ width: '200px' }}
                    value={state.gender}
                    onChange={e => setState({ gender: e.target.value })}
                    inputProps={{
                      name: 'role',
                      id: 'role-helper',
                    }}
                  >
                    {
            genderList.map(row => (
              <MenuItem value={row} key={row}>{row}</MenuItem>
            )
            )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" required>
                  <InputLabel>Age</InputLabel>
                  <Input
                    value={state.ageCategory ? state.ageCategory : ''}
                    autoFocus
                    id="ageCategory"
                    name="ageCategory"
                    onChange={e => setState({ ageCategory: e.target.value })}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">Row</InputLabel>
                  <Input
                    value={state.row ? state.row : ''}
                    autoFocus
                    id="row"
                    name="row"
                    onChange={e => setState({ row: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">Status</InputLabel>
                  <Input
                    value={state.status ? state.status : 'Not Found'}
                    autoFocus
                    id="status"
                    name="status"
                    onChange={e => setState({ status: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">Nationality</InputLabel>
                  <Input
                    value={state.nationality ? state.nationality : ''}
                    autoFocus
                    id="nationality"
                    name="nationality"
                    onChange={e => setState({ nationality: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">National ID</InputLabel>
                  <Input
                    value={state.nationalId === 'undefined' ? '' : state.nationalId}
                    autoFocus
                    id="nationalId"
                    name="nationalId"
                    onChange={e => setState({ nationalId: e.target.value })}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.buttons}>
              <Button
                style={{ marginTop: '20px', marginBottom: '20px' }}
                type="submit"
        // fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
              >
            Submit
              </Button>
            </div>
            <Dialog
              open={state.open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">User Edited</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
            Edit Complete
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
            Close
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </Paper>
      </Grid>
    </div>

  )
}

export default EditParticipant
