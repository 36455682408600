import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon,
} from '@material-ui/icons'

import ThemeContext from '../contexts/ThemeContext'

import config from './config'
import Root from './Layout/Root'
import Header from './Layout/Header'
import Nav from './Layout/Nav'
import Content from './Layout/Content'
import Footer from './Layout/Footer'

const useStyles = makeStyles(({ mixins, palette }) => ({
  logo: {
    ...mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.text.primary,
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

const ThemeLayout = ({ header, menus, children }) => {
  const classes = useStyles()
  const { darkTheme, lightTheme } = useContext(ThemeContext)
  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addListener(e => e.matches && darkTheme())
    window.matchMedia('(prefers-color-scheme: light)').addListener(e => e.matches && lightTheme())
  })
  return (
    <Root config={config} style={{ minHeight: '100vh' }}>
      <Header
        menuIcon={{
          inactive: <MenuIcon />,
          active: <ChevronLeftIcon />,
        }}
      >
        {header}
      </Header>
      <Nav
        collapsedIcon={{
          inactive: <ChevronLeftIcon />,
          active: <ChevronRightIcon />,
        }}
        header={(
          <div className={classes.logo}>
            <Typography variant="h6" color="inherit" noWrap>Race Pack</Typography>
          </div>
        )}
      >
        {menus}
      </Nav>
      <Content>
        {children}
      </Content>
      <Footer>Copyright © 2020 Thaidotrun Co., Ltd.</Footer>
    </Root>
  )
}
ThemeLayout.propTypes = {
  header: PropTypes.node,
  menus: PropTypes.node,
  children: PropTypes.node,
}
ThemeLayout.defaultProps = {
  header: null,
  menus: null,
  children: null,
}

export default ThemeLayout
