/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import {
  FormControl, InputLabel, Input, Button, Typography, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, Paper, Snackbar
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { useMutation,useQuery} from '@apollo/react-hooks'
import { useHistory, useParams } from 'react-router-dom'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import mimeTypes from 'mime'
import _get from 'lodash/get'
import { makeStyles } from '@material-ui/styles'

// import { Main } from '@oclif/command'
import eventByIdQuery from '../graphql/query/eventById'
import checkedBibMutation from '../graphql/mutation/checkedBib'
import Breadcrumb from '../components/BreadcrumbLayout'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))
const AddParticipant = () => {
  const params = useParams()
  const classes = useStyles()
  const [bib, setBib] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [gender, setGender] = useState('')
  const [ageCategory, setAgeCategory] = useState('')
  const [row, setRow] = useState('')
  const [nationality, setNationality] = useState('')
  const [nationalId, setnNationalId] = useState('')
  const [shirtSize, setShirtSize] = useState('')
  const [shirtType, setShirtType] = useState('')
  const [ticketCode, setTicketCode] = useState('')
  const [ticketType, setTicketType] = useState('')

  // const [logo, setLogo] = useState('')
  const [file, setFile] = useState('')
  const [fileImg, setFileImg] = useState('')
  const [open, setOpen] = useState(false)
  const [openSnack, setOpenSnack] = useState(false);
  const history = useHistory()
  const {data} = useQuery(eventByIdQuery, {
    variables: {
      _id: params.eventId,
    },
  })
  const reader = new FileReader()
  const handleClose = () => {
    setOpen(false)
    history.push(`/event/${params.eventId}/participant`)
    window.location.reload()
  }
  const [checkedBib] = useMutation(checkedBibMutation)
  console.log(params,'params');
  const handleSubmit = async (e) => {
    e.preventDefault()
    
      const record = {
        bib : `${bib}`,
        firstname : firstName,
        lastname : lastName,
        displayName : displayName,
        birthDate : birthDate,
        ...(_get(data,'eventId.isFaceCheckin') && {
          photo: file
        }),
        gender : gender,
        ageCategory : ageCategory,
        row : row,
        nationality : nationality,
        nationalId : `${nationalId}`,
        shirt: {  size: shirtSize, type: shirtType }, 
        ...(ticketCode && {
          options: { ticketCode: ticketCode, ticketType: ticketType},
        }),
        eventId: params.eventId
      }
      console.log(record,'rec');
      await checkedBib(
      {
        variables: {
          filter: record
        }
      }).then((res) => {
        if(res.data.checkedBib === null){
          setOpenSnack(true);
        }else{
          setOpen(true)
        }
      }).catch((e) => {
        console.error(e)
      })
  }
  const selectFile = async (e) => {
    e.preventDefault()
    const tempFile = e.currentTarget.files[0]
    setFileImg(tempFile)
    reader.readAsDataURL(tempFile)
    reader.onload = async (event) => {
      const extType = mimeTypes.getExtension(tempFile.type)
      const ext = mimeTypes.getType(extType)
      const response = await fetch(process.env.REACT_APP_UPLOAD_SERVER, {
        method: 'POST',
        body: JSON.stringify({
         ext: `${ext}`
        }),
        headers: {
          'Content-Type': 'application/json',
          
        }
      })
      const result = await response.json()
      await axios.put(result.uploadUri ,tempFile, {
        headers: {
          'Content-Type': `${ext}`,
          'x-goog-acl': 'public-read'
        }
      })
      setFile(result.downloadUri)
    }
  }
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const genderList = ['male', 'female']
  return (
    <React.Fragment>
      <Breadcrumb path="/events" titleLink="Event" titleTypo="Create Participant" />
      <br />
      <Grid
        className={classes.layout}
      // alignItems="center"
      >
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit}>

            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h4" gutterBottom>Create Participant</Typography>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography style={{ marginTop: '20px' }} variant="h6" gutterBottom>Detail</Typography>
            </Grid>
            <Grid

              container
              spacing={3}
            >

              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">Bib</InputLabel>
                  <Input
                    autoFocus
                    id="bib"
                    name="bib"
                    onChange={(e) => setBib(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">First Name</InputLabel>
                  <Input
                    autoFocus
                    id="firstName"
                    name="firstName"
                    onChange={e => setFirstName(e.target.value)}
                  />

                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel htmlFor="eventName">Last Name</InputLabel>
                  <Input
                    id="lastName"
                    name="lastName"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel>Display Name</InputLabel>
                  <Input
                    autoFocus
                    id="displayName"
                    name="displayName"
                    onChange={(e) => setDisplayName(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel>Birth Date</InputLabel>
                  <Input
                    autoFocus
                    id="birthdate"
                    name="birthdate"
                    onChange={(e) => setBirthDate(e.target.value)}
                  />
                </FormControl>
              </Grid>
              { _get(data,'eventId.isFaceCheckin') && <Grid item xs={12}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel>Photo</InputLabel>
                  <Button variant="contained" size="small" component="label" className={classes.button} style={{ marginTop: '20px', marginLeft: '400px', width: '120px' }} startIcon={<CloudUploadIcon />}>
                    {fileImg.name ? fileImg.name : 'Choose Image'}
                    <input
                      onChange={selectFile}
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      style={{ display: 'none' }}
                    />
                  </Button>
                </FormControl>
              </Grid>}
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    select
                    style={{ width: '200px' }}
                    id="gender"
                    name="gender"
                    // value={state.gender}
                    onChange={e => setGender(e.target.value )}
                    inputProps={{
                      name: 'role',
                      id: 'role-helper',
                    }}
                  >
                    {genderList.map(row => (
                      <MenuItem value={row} key={row}>{row}</MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" required>
                  <InputLabel>Age Category</InputLabel>
                  <Input
                    autoFocus
                    id="ageCategory"
                    name="ageCategory"
                    onChange={e => setAgeCategory(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" required>
                  <InputLabel>Row</InputLabel>
                  <Input
                    autoFocus
                    id="row"
                    name="row"
                    onChange={e => setRow(e.target.value)}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <FormControl margin="normal" required>
                  <InputLabel>Status</InputLabel>
                  <Input
                    autoFocus
                    id="status"
                    name="status"
                    onChange={e => setCodeEvent(e.target.value)}
                  />
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" required>
                  <InputLabel>Nationality</InputLabel>
                  <Input
                    autoFocus
                    id="nationality"
                    name="nationality"
                    onChange={e => setNationality(e.target.value )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" required>
                  <InputLabel>National ID</InputLabel>
                  <Input
                    autoFocus
                    id="nationalId"
                    name="nationalId"
                    onChange={e => setnNationalId(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <FormControl margin="normal" fullWidth required>
                <InputLabel>[Cover Photo]</InputLabel>
                <Input
                  id="Logo"
                  name="Logo"
                  onChange={e => setLogo(e.target.value)}
                />
              </FormControl>
            </Grid> */}
            {/* {shirts.map((k, index) => (
              <div key={`${k.slug}-shirts`}>

                <Typography style={{ marginTop: '20px', marginBottom: '10px' }} variant="h6" gutterBottom>Shirt{index + 1}</Typography>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="outlined-read-only-input"
                      label="Slug Shirt"
                      defaultValue={k.slug}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <TextField
                      id="outlined-read-only-input"
                      label="ชนิดเสื้อ [Type]: "
                      defaultValue={k.type}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <TextField
                      style={{ height: '32px' }}
                      label="รูปภาพเสื้อ [Image]:"
                      defaultValue={k.image}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />
                  </Grid>
                  <Grid item xs={12} sm={1} style={{ marginTop: '20px' }}>
                    <Fab

                      variant="extended"
                      style={{ height: '32px' }}
                      size="small"
                      color="primary"
                      aria-label="add"
                      onClick={() => removeShirt(index)}
                    >
                      <DeleteIcon />
                    </Fab>
                  </Grid>
                </Grid>

              </div>
            ))} */}

          <Typography style={{ marginTop: '20px', marginBottom: '10px' }} variant="h6" gutterBottom>Shirt Detail</Typography>
          <Grid

          container
          spacing={3}
          >
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" fullWidth required>
                <InputLabel>Shirt Size</InputLabel>
                <Input
                  autoFocus
                  id="shirtSize"
                  name="shirtSize"
                  onChange={(e) => setShirtSize(e.target.value)}
                  // value={slugShirt}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" fullWidth required>
                <InputLabel>Type Shirt</InputLabel>
                <Input

                  autoFocus
                  id="shirtType"
                  name="shirtType"
                  onChange={(e) => setShirtType(e.target.value)}
                  // value={typeShirt}

                />
              </FormControl>
            </Grid>
          </Grid>
            {/* <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>Image Shirt</InputLabel>
                <Input
                  autoFocus
                  id="imageShirt"
                  name="imageShirt"
                  onChange={(e) => setImageShirt(e.target.value)}
                  value={imageShirt}

                />
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={12} style={{ marginTop: '20px' }}>
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="add"
                onClick={addShirt}
              >

                <AddIcon />
                Add Shirt

              </Fab>
            </Grid> */}

            {/* {tickets.map((k, index) => (
              <div key={`${k.ticketType}`}>

                <Typography style={{ marginTop: '20px', marginBottom: '10px' }} variant="h6" gutterBottom>Ticket{index + 1}</Typography>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="outlined-read-only-input"
                      label="ticketType"
                      defaultValue={k.ticketType}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <TextField
                      id="outlined-read-only-input"
                      label="StartDate: "
                      defaultValue={k.startDate}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <TextField
                      id="outlined-read-only-input"
                      label="EndDate: "
                      defaultValue={k.endDate}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}

                    />
                  </Grid>

                  <Grid item xs={12} sm={1} style={{ marginTop: '20px' }}>
                    <Fab

                      variant="extended"
                      style={{ height: '32px' }}
                      size="small"
                      color="primary"
                      aria-label="add"
                      onClick={() => removeTicket(index)}
                    >
                      <DeleteIcon />
                    </Fab>
                  </Grid>
                </Grid>

              </div>
            ))} */}

            <Typography style={{ marginTop: '20px', marginBottom: '10px' }} variant="h6" gutterBottom>Ticket</Typography>
            <Grid
            container
            spacing={3}
            >

              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required={_get(data,'eventId.options.search.qrcode')}>
                  <InputLabel>Ticket Code</InputLabel>
                  <Input
                    autoFocus
                    id="ticketCode"
                    name="ticketCode"
                    onChange={(e) => setTicketCode(e.target.value)}
                    // value={ticketType}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel>Ticket Type</InputLabel>
                  <Input
                    autoFocus
                    id="ticketType"
                    name="ticketType"
                    onChange={(e) => setTicketType(e.target.value)}
                    // value={ticketType}
                  />
                </FormControl>
              </Grid>
            </Grid>

            {/* <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>StartDate</InputLabel>
                <Input

                  autoFocus
                  id="startDate"
                  name="startDate"
                  type="date"
                  onChange={e => handleStartDate(e)}

                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>EndDate</InputLabel>
                <Input

                  autoFocus
                  id="endDate"
                  name="endDate"
                  type="date"
                  onChange={e => handleEndDate(e)}

                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="add"
                disabled = {timeDate}
                onClick={addTicketType}
              >

                <AddIcon />
                Add Ticket Type

              </Fab>
            </Grid> */}

            {/* <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom>Print</Typography>
            <Grid

              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setPrint(e.target.checked)}
                    />
                  )}
                  label="Print"
                />
              </Grid>
            </Grid>
            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom> Search Modes</Typography>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setQRSearch(e.target.checked)}
                    />
                  )}
                  label="QR Code"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setIDSearch(e.target.checked)}
                    />
                  )}
                  label="ID Card"
                />
              </Grid>
            </Grid>
            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom>Face Detection</Typography>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setUploadFace(e.target.checked)}
                    />
                  )}
                  label="From Upload"
                />
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setIsFaceCheckin(e.target.checked)}
                    />
                  )}
                  label="FaceCheckin"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {IDSearch && (
                  <div>
                    <FormControlLabel
                      control={(
                        <Switch
                          onChange={(e) => setIDFace(e.target.checked)}
                        />
                      )}
                      label="ID Card"
                    />

                  </div>
                )}
              </Grid>
            </Grid>
            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom>Self Checkin</Typography>
            <Grid

              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setSelfCheckin(e.target.checked)}
                    />
                  )}
                  label="selfCheckin"
                />
              </Grid>
            </Grid>
            <Typography style={{ marginTop: '20px', marginBottom: '20px' }} variant="h6" gutterBottom> Others</Typography>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      onChange={(e) => setIsShirtImage(e.target.checked)}
                    />
            )}
                  label="Shirt-Image"
                />
              </Grid>
            </Grid> */}
            <div className={classes.buttons}>
              <Button
                style={{ marginTop: '20px', marginBottom: '20px' }}
                type="submit"
                // fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Submit
              </Button>
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Participant Created</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Participant has been created.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
              <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
                    Bib is already!
                </Alert>
              </Snackbar>
          </form>
        </Paper>
      </Grid>
    </React.Fragment>
  )
}

export default AddParticipant
