import React, {
  useContext, useEffect, useRef, useCallback,
} from 'react'
import Webcam from 'react-webcam'

import WebcamContext from '../contexts/WebcamContext'

const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: 'user',
}

const useInterval = (callback, delay = 1000) => {
  const savedCallback = useRef()
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])
  useEffect(() => {
    const tick = () => {
      savedCallback.current()
    }
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}
const WebcamPanel = () => {
  const { setImage } = useContext(WebcamContext)
  const webcamRef = useRef(null)
  const capture = useCallback(
    () => {
      setImage(webcamRef.current.getScreenshot())
    },
    [webcamRef, setImage]
  )
  useInterval(() => {
    capture()
  }, 200)
  return (
    <Webcam
      audio={false}
      width={640}
      height={480}
      ref={webcamRef}
      screenshotFormat="image/jpeg"
      videoConstraints={videoConstraints}
    />
  )
}

export default WebcamPanel
