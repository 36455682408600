import React, { useState, useEffect, useContext } from 'react'
import { useParams, Link as Linkreact } from 'react-router-dom'
import XLSX from 'xlsx'
import {
  Typography, Paper, TextField, Button, FormControl, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, CircularProgress, Grid, Link, Table, TableBody, TableCell, TableHead, TableRow, Checkbox
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery, useMutation } from '@apollo/react-hooks'
// import eachLimit from 'async/eachLimit'
// import asyncify from 'async/asyncify'
import removeParticipantsMutation from '../graphql/mutation/removeParticipants'
import Breadcrumb from '../components/BreadcrumbLayout'
import participantsQuery from '../graphql/query/participants'
// import createParticipantMutation from '../graphql/mutation/createParticipant'
import createOrUpdateParticipantMutation from '../graphql/mutation/createOrUpdateParticipant'
import eventByIdQuery from '../graphql/query/eventById'
import activitiesCountQuery from '../graphql/query/activitiesCount'
import AuthContext from '../contexts/AuthContext'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  input: {
    display: 'none',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'start',
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
  },
  MuiTableCell : {
    paddingLeft: '5px'
  },
  MuiButton: {
    backgroundColor : 'red'
  },
  MuiButton2: {
    backgroundColor : 'green'
  }
}))

const EventsPage = () => {
  const { user } = useContext(AuthContext)
  const params = useParams()
  const { loading, data, refetch } = useQuery(participantsQuery, {
    variables: {
      filter: {
        eventId: params.eventId,
      },
    },
  })
  const event = useQuery(eventByIdQuery, {
    variables: {
      _id: params.eventId,
    },
  })
  const activitiesCount = useQuery(activitiesCountQuery, {
    variables: {
      eventId: params.eventId,
    },
  })
  // const [createParticipant] = useMutation(createParticipantMutation)
  const [createOrUpdateParticipant] = useMutation(createOrUpdateParticipantMutation)
  const [removeParticipants] = useMutation(removeParticipantsMutation)

  const classes = useStyles()
  const [bibFilter, setBibFilter] = useState('')
  const [rowFilter, setRowFilter] = useState('')
  const [firstNameFilter, setFirstNameFilter] = useState('')
  const [lastNameFilter, setLastNameFilter] = useState('')
  const [status, setStatus] = useState('')
  const [json, setJson] = useState('')
  const [open, setOpen] = useState(false)
  const [importing, setImporting] = useState(false)
  const [progress, setProgress] = useState(0)
  const [checkIn, setCheckIn] = useState(0)
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openDialog, setOpenDialog] = useState(false)


  useEffect(() => {
    if (activitiesCount.data) {
      const totalCheckin = activitiesCount.data.activitiesCount.filter(({ lastActivity }) => !!lastActivity).reduce((prev, { count }) => (prev + count), 0)
      setCheckIn(totalCheckin)
    }
  }, [activitiesCount])

  const selectFile = async (e) => {
    e.preventDefault()
    e.currentTarget.files[0].arrayBuffer().then((arrayBuffer) => {
      const wb = XLSX.read(arrayBuffer, { type: 'buffer' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const jsonObj = XLSX.utils.sheet_to_json(ws)
      setJson(jsonObj)
      setOpen(true)
    })
  }
  const handleImport = async () => {
    setOpen(false)
    setImporting(true)
    const recordCount = json.length
    const records = [...json]
    while (records.length > 0) {
      await Promise.all(records.splice(0, 10).map((record) => { // eslint-disable-line no-await-in-loop
        const {
          birthdate = record.birthdate || record.birth_date,
          bib,
          row,
          firstname = record.firstName || record.firstname || record.first_name,
          lastname = record.lastName || record.lastname || record.last_name,
          displayName,
          shirtType,
          shirtSize,
          gender,
          ageCategory,
          nationality,
          nationalId = record.nationalId || record.national_id,
          photo,
          ...options
        } = record
        const mappedData = {
          bib: String(bib), row, firstname, lastname, birthdate, displayName, gender, nationalId: String(nationalId), ageCategory, photo, nationality, shirt: { type: shirtType, size: shirtSize }, options, eventId: params.eventId,
        }
        return createOrUpdateParticipant(
          {
            variables: {
              filter: mappedData,
            },
          },
        ).catch((e) => {
          console.error(e)
        })
      }))
      setProgress(((recordCount - records.length) * 100) / recordCount)
    }
    refetch()
    event.refetch()
    setProgress(100)
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    const filter = {}
    if (bibFilter !== '') {
      filter.bib = bibFilter
    }
    if (rowFilter !== '') {
      filter.row = rowFilter.replace(/r/g, 'R')
    }
    if (firstNameFilter !== '') {
      filter.firstName = firstNameFilter
    }
    if (lastNameFilter !== '') {
      filter.lastName = lastNameFilter
    }
    if (status !== '') {
      filter.status = status
    }
    filter.eventId = params.eventId
    refetch({ filter })
  }
  if (user.role === 'Staff') {
    return ('Cannot Access')
  }
  if (event.data === undefined) {
    return 'Loading'
  }
  if (event.data.eventId === null) {
    return "Event Doesn't Exist"
  }

  const handleSelectAllChange = (itemId) => {
    console.log(selectAll,'all');
    if (!selectAll) {
      setSelectedItems(data.participants.map((item) => item.id === itemId ? { ...item, checked: !item.checked } : item))
    } else {
      setSelectedItems([]);
    }
    setSelectAll(!selectAll);
  };
  const handleCheckboxChange = (item) => {
    console.log(item);
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((selected) => selected !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
    setSelectAll(false)
  };

  const handleOpen = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleDelete = async () => {
    // const removeParticipantsResult = await removeParticipants({variables: { id : data.participants.map((r) => r.id) }})
    console.log(selectedItems.map((r) => r._id),'selectedItems');
    try {
        await removeParticipants({
          variables: { _id : selectedItems.map((r) => r._id), selectAll : selectAll, eventId : params.eventId},
        }).then(res => {
          refetch()
          window.location.reload()
        })
      setSelectAll(selectAll)
      setSelectedItems([]);
      setOpenDialog(false);
    } catch (error) {
      console.error('Error deleting items:', error);
    }
  }

  return (
    <div>
      <Breadcrumb className={classes.root} path="/events" titleLink="Event" titleTypo={event.data.eventId.name} />
      <br />
      <Typography variant="h5">{event.data ? event.data.eventId.name : ''}</Typography>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={1}
          className={classes.filterBox}
        >
          <Grid item xs={12} sm={2}>
            <FormControl margin="normal" fullWidth required>
              <TextField
                label="First Name"
                defaultValue={bibFilter}
                className={classes.textField}
                style={{ marginLeft: '20px' }}
                onChange={(e) => setFirstNameFilter(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}>
            <FormControl margin="normal" fullWidth required>
              <TextField
                label="Last Name"
                defaultValue={bibFilter}
                className={classes.textField}
                style={{ marginLeft: '20px' }}
                onChange={(e) => setLastNameFilter(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}>
            <FormControl margin="normal" fullWidth required>
              <TextField
                label="Bib"
                defaultValue={bibFilter}
                className={classes.textField}
                style={{ marginLeft: '20px' }}
                onChange={(e) => setBibFilter(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}>
            <FormControl margin="normal" fullWidth required>
              <TextField
                label="Row"
                defaultValue={rowFilter}
                className={classes.textField}
                style={{ marginLeft: '20px' }}
                onChange={(e) => setRowFilter(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}>
            <FormControl margin="normal" fullWidth required>
              <TextField
                label="Status"
                defaultValue={status}
                className={classes.textField}
                style={{ marginLeft: '20px' }}
                onChange={(e) => setStatus(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{ marginTop: '20px' }}
              size="small"
              fullWidth
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={2} sm={1} >
            <div className={classes.buttons}>
              <Typography variant="h6" style={{ marginTop: '20px', marginLeft: '10px' }}>
                {event.data ? `Checked In: ${checkIn} / ${event.data.eventId.participantCount}` : ''}
              </Typography>
            </div>
          </Grid>
          <Grid container spacing={1} xs={12} sm={2} md={3}>
            <div className={classes.buttons}>
              <Button variant="contained" size="small" component="label" className={classes.button} style={{ marginTop: '20px', marginLeft: '20px' }}>
                Import Participants
                <input
                  onChange={selectFile}
                  accept=".xlsx"
                  className={classes.input}
                  id="contained-button-file"
                  type="file"
                  style={{ display: 'none' }}
                />
              </Button>
            </div>
            <div className={classes.buttons}>
              {selectedItems.length > 0 && <Button onClick={handleOpen} variant="contained" size="small" component="label" className={classes.MuiButton} style={{ marginTop: '20px', marginLeft: '20px', color: 'white'}}>
                DELETED
              </Button> }
            </div>
            <Grid container xs={2} sm={2} >
              <div className={classes.buttons}>
                  <Button variant="contained" to={`/event/${params.eventId}/participant/add`}  size="small" component={Linkreact} color="primary" style={{ marginTop: '20px', marginLeft: '20px', color: 'white', textAlign: 'center'}}>
                    ADD PARTICIPANT
                  </Button>
              </div>
            </Grid>
            <Dialog
              open={openDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete the selected items?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDelete} color="primary">
                  Confirm
                </Button>
                <Button onClick={handleClose} color="secondary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </form>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                  className={classes.MuiTableCell}
              >
              <Checkbox
                  color="primary"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell align="right">Bib</TableCell>
              <TableCell>Row</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || !data ? [] : data.participants.map((row) => (
              <TableRow 
              key={row._id}
              role="checkbox"
              >
                <TableCell padding="checkbox">
                  <Checkbox
                      color="primary"
                      checked={selectedItems.includes(row)}
                      onChange={() => handleCheckboxChange(row)}
                    />
                </TableCell>
                <TableCell component="th" scope="row">{row.firstname}</TableCell>
                <TableCell component="th" scope="row">{row.lastname}</TableCell>
                <TableCell align="right">{row.bib ? row.bib : '-'}</TableCell>
                <TableCell>{row.row ? row.row : '-'}</TableCell>
                <TableCell>{row.lastActivity ? row.lastActivity.status : '-'}</TableCell>
                <TableCell><Link to={`/event/${params.eventId}/${row._id}/edit`} component={Linkreact}>Edit</Link></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{importing ? 'Import?' : 'Importing'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`This file has ${json.length} row(s), are you sure you want to import?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            No
          </Button>
          <Button onClick={() => handleImport()} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={importing}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.layout}
      >
        <DialogTitle id="alert-dialog-title">Importing</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {(progress === 100) ? 'Finished' : 'Importing'}
          </DialogContentText>
          {(progress < 100) ? <CircularProgress variant="indeterminate" value={progress} /> : ''}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setImporting(false)} color="primary" autoFocus disabled={!(progress === 100)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EventsPage

