import gql from 'graphql-tag'

export default gql`
query ($eventId: String!) {
  activitiesPagination (filter: {eventId: $eventId, OR: [{status: "checkin"}, {status: "pickup"}]}, sort: CREATEDBYID_DESC, perPage: 30) {
    items {
      _id
      status
      createdAt
      participant {
        _id
        firstname
        lastname
        bib
      }
    }
  }
}
`
