import gql from 'graphql-tag'

export default gql`
query eventId($_id:MongoID!){
  eventId(_id:$_id){
    participantCount
    name
    organizerId
    logo
    isFaceCheckin
    isShirtImage
    slug
    code
    logo
    shirts{
      slug
      image
      type
    }
    tickets{
      ticketType
      dateTicket
      startDate
      endDate
    }
    options{
      search{
        qrcode
        idcard
      }
      face{
        upload
        idcard
      }
      print
      selfCheckin
      autoPickup
    }
  }
}
`
