import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'

import themes from '../theme'

const ThemeContext = createContext()

const colorSchema = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'

export const ThemeProvider = (props) => {
  const { children } = props
  const [theme, setTheme] = useState(colorSchema)
  const toggleTheme = () => setTheme(theme === 'dark' ? 'light' : 'dark')
  const darkTheme = () => setTheme('dark')
  const lightTheme = () => setTheme('light')
  const muiTheme = createMuiTheme(themes[theme])
  return (
    <ThemeContext.Provider value={{
      theme, toggleTheme, darkTheme, lightTheme,
    }}
    >
      <MuiThemeProvider theme={muiTheme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}
ThemeProvider.propTypes = {
  children: PropTypes.node,
}
ThemeProvider.defaultProps = {
  children: null,
}

export const ThemeConsumer = ThemeContext.Consumer

export default ThemeContext
