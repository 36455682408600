import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

const WebcamContext = createContext()

export const WebcamProvider = ({ children }) => {
  const [image, setImage] = useState(null)
  return (
    <WebcamContext.Provider value={{ image, setImage }}>
      {children}
    </WebcamContext.Provider>
  )
}
WebcamProvider.propTypes = {
  children: PropTypes.node,
}
WebcamProvider.defaultProps = {
  children: null,
}

export const WebcamConsumer = WebcamContext.Consumer

export default WebcamContext
