const config = {
  navAnchor: 'left',
  navVariant: {
    xs: 'temporary',
    sm: 'temporary',
    md: 'temporary',
  },
  navWidth: {
    xs: 240,
    sm: 256,
    md: 320,
  },
  collapsible: {
    xs: false,
    sm: false,
    md: false,
  },
  collapsedWidth: {
    xs: 64,
    sm: 64,
    md: 64,
  },
  clipped: {
    xs: true,
    sm: true,
    md: true,
  },
  headerPosition: {
    xs: 'relative',
    sm: 'relative',
    md: 'relative',
  },
  squeezed: {
    xs: true,
    sm: true,
    md: true,
  },
  footerShrink: {
    xs: true,
    sm: true,
    md: true,
  },
}

export default config
