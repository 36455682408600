import React, { useEffect } from 'react'
// import { makeStyles } from '@material-ui/styles'

import WebcamPanel from '../components/WebcamPanel'
import { WebcamProvider } from '../contexts/WebcamContext'
import { loadModels } from '../face'

// const useStyles = makeStyles({})

const IdcardReaderPage = () => {
  // const classes = useStyles()
  useEffect(() => {
    const init = async () => {
      await loadModels()
    }
    init()
  }, [])
  return (
    <WebcamProvider>
      <WebcamPanel />
    </WebcamProvider>
  )
}

export default IdcardReaderPage
