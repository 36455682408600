/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
import React, { useContext } from 'react'
// import { Link } from 'react-router-dom'
import {
  Typography, Paper, Button, ButtonGroup, Grid, Fab
} from '@material-ui/core'
// import { Home as HomeIcon } from '@material-ui/icons'
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useQuery } from '@apollo/react-hooks'
// import { Fab } from '@material-ui/co'
import { Link as Linkreact } from 'react-router-dom'
import NoteAddTwoToneIcon from '@material-ui/icons/NoteAddTwoTone'
import ListAltIcon from '@material-ui/icons/ListAlt'

import eventsQuery from '../graphql/query/events'
import AuthContext from '../contexts/AuthContext'
import Breadcrumb from '../components/BreadcrumbLayout'

const useStyles = makeStyles(theme => ({

  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  bread: {
    padding: theme.spacing(1, 2),
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    display: 'flex'
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },

}))

const EventsPage = () => {
  const { user } = useContext(AuthContext)
  const classes = useStyles
  let variables = {
    filter: {
      organizerId: user._id
    }
  }
  if (user.role === 'Admin') {
    variables = {}
  }
  const events = useQuery(eventsQuery, { variables })
  if (user.role === 'Staff') {
    return ('Cannot Access')
  }
  return (
    <div>

      <Breadcrumb titleTypo="Events" />
      <br />
      <Grid
        className={classes.layout}
      >
        <Grid

          container
          direction="row"
          justify="space-between"
          alignItems="flex-end"
        >
          <Typography variant="h5">Events</Typography>
          {user.role === 'Admin' ? (
            <Fab variant="extended" to="/event/create" color="primary" style={{ height: '32px' }} component={Linkreact}>
              <NoteAddTwoToneIcon />
        Add Event
            </Fab> 
          ) : null}
        </Grid>
        <Paper className={classes.paper} style={{ marginTop: '10px' }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Event Name</TableCell>
                <TableCell># of Participants</TableCell>
                {/* <TableCell align="right">Action</TableCell> */}
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {events.loading || events.data === undefined ? [] : events.data.events.map(row => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">{row.name}</TableCell>
                  <TableCell>{row.participantCount ? row.participantCount : 'Not Found'}</TableCell>
                  <TableCell align="right">
                    <ButtonGroup color="primary" className={classes.buttons}>
                      <Button variant="contained" size="small" style={{ height: '32px' }} color="primary" to={`/event/${row._id}/stats`} component={Linkreact} className={classes.button}>
                        <EqualizerIcon />
                      </Button>
                      <Button variant="contained" size="small" style={{ height: '32px' }} color="primary" to={`/event/${row._id}/edit`} component={Linkreact} className={classes.button}>
                        <EditIcon />
                      </Button>
                      <Button variant="contained" size="small" style={{ height: '32px' }} color="primary" to={`/event/${row._id}/participant`} component={Linkreact} className={classes.button}>
                        <ListAltIcon />
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </div>
  )
}

export default EventsPage

