import React from 'react'
import PropTypes from 'prop-types'
import { Link as Linkreact } from 'react-router-dom'
import { Link, Breadcrumbs, Typography } from '@material-ui/core'

const Breadcrumb = (props) => {
  const {
    path, titleLink, path2, titleLink2, titleTypo,
  } = props
  // const { pathname } = useLocation()
  return (
    <div>
      <Breadcrumbs>

        <Link to="/home" component={Linkreact}>
        Home
        </Link> {titleLink ? (
          <Link to={path} component={Linkreact}>
            {titleLink}
          </Link>
        ) : null}
        {titleLink2 ? (
          <Link to={path2} component={Linkreact}>
            {titleLink2}
          </Link>
        ) : null}
        {titleTypo ? (
          <Typography>
            {titleTypo}
          </Typography>
        ) : null}

      </Breadcrumbs>
      <br />
    </div>
  )
}
Breadcrumb.defaultProps = {
  path: '',
  titleLink: '',
  titleTypo: '',
  path2: '',
  titleLink2: '',
}
Breadcrumb.propTypes = {
  path: PropTypes.string,
  titleLink: PropTypes.string,
  path2: PropTypes.string,
  titleLink2: PropTypes.string,
  titleTypo: PropTypes.string,
}

export default Breadcrumb
