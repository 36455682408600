import gql from 'graphql-tag'

export default gql`
query events($filter: FilterFindManyEventInput ){
  events(filter:$filter){
    _id
    name
    organizerId
    participantCount
  }
}
`
