import React, { useState, useContext } from 'react'
import {
  Typography, Paper, Avatar, FormControl, InputLabel, Input, Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons'
import { useMutation } from '@apollo/react-hooks'

import loginMutation from '../graphql/mutation/login'
import AuthContext from '../contexts/AuthContext'

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: spacing(3),
    marginRight: spacing(3),
    [breakpoints.up(400 + spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: spacing(2, 3, 3),
  },
  avatar: {
    margin: spacing(1),
    backgroundColor: palette.secondary.main,
    color: palette.secondary.contrastText,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: spacing(1),
  },
  submit: {
    marginTop: spacing(3),
  },
}))

const LoginPage = () => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { setToken } = useContext(AuthContext)
  const [login] = useMutation(loginMutation)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const x = await login(
        {
          variables: {
            email,
            password,
          },
        }
      )
      setToken(x.data.login)
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="username">Username</InputLabel>
            <Input
              autoFocus
              id="username"
              name="username"
              onChange={e => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id="password"
              name="password"
              type="password"
              onChange={e => setPassword(e.target.value)}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Login
          </Button>
        </form>
      </Paper>
    </main>
  )
}

export default LoginPage
